<template>
	<div>
		<!-- select control -->
		<v-select v-if="mutableData.type === 'select'" 
							v-model="mutableData.result"
							:items="mutableData.choises" 
							:label="mutableData.name" 
							:name="mutableData.name"
							dark hide-details
							v-validate.initial="validationRule"/>

		<!-- input control -->
		<v-text-field v-if="mutableData.type === 'text'" 
									v-model="mutableData.result"	
									:label="mutableData.name" 
									dark hide-details	
									:name="mutableData.name"
									v-validate.initial="validationRule"/>

		<!-- validation error -->
		<span class="validation-error">{{ veeErrors.first(mutableData.name) }}</span>
	</div>
</template>

<script>
export default {
	name: 'FormModeItem',
	props: ['data', 'value'],
	data: function(){
		return {
			mutableData: this.data
		}
	},
	computed: {
		validationRule () {
			return this.data.required
        ? 'required'
        : ''
		}
	}
}
</script>

<style lang="scss">
.validation-error {
	color: red;
	font-size: 10px;
}
</style>
