/**
 * @module tools/Brush
 */
import Tool from './Tool.js'

/**
 * Brush and eraser tool
 * @extends Tool
 */
class BrushTool extends Tool {
  /**
   * Create brush or eraser tool
   * @constructor
   * @param {Object} annotator - Annotator vue component reference
   * @param {Boolean} eraser - true for eraser tool and false for generic brush
   */
  constructor (annotator, eraser = false, shortkey = '') {
    super(annotator, eraser ? 'eraser' : 'brush', shortkey)
    this.eraser = eraser
  }
  /**
   * Handle all mouse events for brush/eraser
   * @param {Event} e - mouse event 
   */
  handleMouse (e) {
    switch (e.type) {
      case 'mousedown': {
        // Left button only								
        if (e.button === 0) {
          this.startFigure(this.annotator.activeTool === 'brush' 
            ? this.annotator.brushColor
            : '#ffffff', this.annotator.brushWidth)
        }

        break
      }			
      // Handle mouse move
      case 'mousemove': {
        if (this.current.isDrawing) {
          this.addPoint(this.current.x, this.current.y)
        }
        
        break
      }
      // Stop drawing on mouseout or mouseup
      case 'mouseup':
      case 'mouseout': {
        if (this.current.isDrawing) {
          this.addPoint(this.current.x, this.current.y)
          this.finishDrawing()
        }

        // Reset drawing params in any case, this prevents brush cursor from drawing on mouseout
        this.current.x = false
        this.current.y = false
        this.current.isDrawing = false
        break
      }
    }
  }
  /**
   * Draw given brush/eraser figure on given canvas
   * @param {CanvasRenderingContext2D} canvasCtx - target canvas context
   * @param {Object} figure - figure to draw
   * @param {Number} alpha - alpha level to use for brush (eraser always use 1)
   */
  drawFigure (canvasCtx, figure, alpha) {
    canvasCtx.strokeStyle = figure.tool === 'brush' ? figure.color : '#ffffff'
    canvasCtx.globalAlpha = figure.tool === 'brush' ? alpha : 1

    if (figure.points.length === 1) {
      canvasCtx.lineWidth = 1
      canvasCtx.beginPath()        
      canvasCtx.arc(figure.points[0][0], figure.points[0][1], figure.lineWidth / 2, 0, Math.PI * 2)
      canvasCtx.fillStyle = canvasCtx.strokeStyle
      canvasCtx.fill()      
    } else {
      canvasCtx.lineWidth = figure.lineWidth
      canvasCtx.beginPath()        
      canvasCtx.moveTo(figure.points[0][0], figure.points[0][1])

      for (let i = 0; i < figure.points.length-2; i++) {
        canvasCtx.lineTo(figure.points.length === 1 ? figure.points[i][0] : figure.points[i+1][0],
                         figure.points.length === 1 ? figure.points[i][1] : figure.points[i+1][1])
      }

      canvasCtx.stroke()        						
    }
  }
}

export default BrushTool