<template lang="pug">
  div
    DebugPanel() 

    template
      v-navigation-drawer.inshadow(left, fixed, permanent, app)
        template(v-slot:prepend)
          v-list-item(style="background-color: #4b0f42")
            v-list-item-content.py-1
              div#logo
                img(src="@/assets/logo.jpg")

          template(v-if="mode === 'segmentation'")
            div.group.text-capitalize {{ segmentation.name }}
            SegmentationClassTool(:colors="segmentation.colors" :activeColor="brushColor" @change="setBrushColor($event)")

            div.group Tools

            v-list.py-1(dense,nav)
              MenuTool(icon="mdi-brush", title="Brush", name="brush", :activeTool="activeTool")
              MenuTool(icon="mdi-shape-polygon-plus", title="Polygon", name="poly", :activeTool="activeTool")
              MenuTool(icon="mdi-auto-fix", title="Magic wand", name="magicwand", :activeTool="activeTool")
              MenuTool(icon="mdi-format-color-fill", title="Flood fill", name="fill", :activeTool="activeTool")                              
              MenuTool(icon="mdi-eraser", title="Eraser", name="eraser", :activeTool="activeTool")                                        
              MenuItem(icon="mdi-undo", title="Undo", :disabled="!selectedImage || selectedAnnotations.length === 0", ulFirstLetter, @click="onUndo()")
              MenuItem(v-if="mode === 'segmentation' && selectedIndex > 0", 
                      :disabled="images[selectedIndex - 1] && images[selectedIndex - 1].annotations.length === 0 && !images[selectedIndex - 1].mask",
                      icon="mdi-domino-mask", title="Copy the last mask", 
                      @click="onCopyLastMask()")

          template(v-if="mode === 'box'")
            div.group.text-capitalize {{ boundingBox.name }}
            SegmentationClassTool(:colors="boundingBox.colors" :activeColor="brushColor" @change="setBrushColor($event)")

            div.group Tools

            v-list.py-1(dense,nav)
              MenuTool(icon="mdi-rectangle-outline", title="Bounding box", name="box", :activeTool="activeTool")
              MenuItem(icon="mdi-delete", title="Delete", :disabled="!boxSelected", @click="onDeleteBox()")
              MenuItem(v-if="mediaType !== 'video'", icon="mdi-undo", title="Undo", :disabled="!selectedImage || selectedImage.annotations.length === 0", @click="onUndo()")

            template(v-if="mediaType === 'video' && !busy")
              div.group Box copy
              MenuBoxLinearCopy(:boxSelected="boxSelected", :boxSelectedIndex="selectedIndex", :boxRemembered="boxRemembered", :boxRemembered2="boxRemembered2", :boxRememberedFrame="boxRememberedFrame", :boxRememberedFrame2="boxRememberedFrame2", @set="onSetCopyBox", @reset="onResetBox", @show="onShowBox")

              v-list.px-2(dense,nav)
                MenuItem(icon="mdi-content-duplicate", title="Copy using linear", :disabled="!boxRemembered || !boxRemembered2 || boxRememberedFrame2 <= (boxRememberedFrame - 1)", @click="onCopyBoxLinear")
                //
                  MenuItem(icon="mdi-content-duplicate", title="Copy using Lucas-Kanade", :disabled="!boxRemembered || selectedIndex <= boxRememberedFrame", @click="onCopyBoxLucasKanade")
                MenuItem(icon="mdi-cancel", title="Cancel", :disabled="!boxRemembered", @click="onCancelBoxCopy")

          template(v-if="mode === 'line'")
            div.group.text-capitalize {{ line.name }}
            SegmentationClassTool(:colors="line.colors" :activeColor="brushColor" @change="setBrushColor($event)")

            div.group Tools

            v-list.py-1(dense,nav)
              MenuTool(icon="mdi-rectangle-outline", title="Line tool", name="line", :activeTool="activeTool")
              MenuItem(icon="mdi-delete", title="Delete", :disabled="!lineSelected", @click="onDeleteLine()")
              MenuItem(v-if="mediaType !== 'video'", icon="mdi-undo", title="Undo", :disabled="!selectedImage || selectedImage.annotations.length === 0", @click="onUndo()")

          template(v-if="mediaType === 'video' && !busy")
            div.group Navigation ({{ selectedIndex + 1 }} / {{ images.length }})

            VideoNavigation(:value="selectedIndex", :maxFrame="video.frames-1", :disabled="busy", :playing="playingVideo", @input="onNavigateVideo", @play="playingVideo = true", @pause="playingVideo = false")
            MenuSlider(:value="selectedIndex", :max="video.frames-1", :min="0", :step="1", @input="onNavigateVideo")
            div.text-center.mb-1 Video length: {{ videoLength }}

          template(v-if="mode === 'form'")
            div.group {{ form.name }}
            FormMode(:data="form.form")

          template(v-if="mode")
            div.group Settings

            v-list.py-1.editor-sliders(dense,nav)
              MenuSlider(title="Zoom", iconMinus="mdi-magnify-minus", iconPlus="mdi-magnify-plus", v-model="scale", :max="maxZoom", :min="minZoom", :step="1")

              template(v-if="mode === 'segmentation' || mode === 'box'")
                MenuSlider(title="Transparency", iconMinus="mdi-circle-outline", iconPlus="mdi-circle", v-model="brushAlpha", :max="1", :min="0", :step="0.1", @change="forceRedraw()")
              template(v-if="mode === 'segmentation'")
                MenuSlider(title="Brush size", iconMinus="mdi-pencil-minus", iconPlus="mdi-pencil-plus", v-model="brushWidth", :max="100", :min="2", :step="1", @change="forceRedraw()")

              template(v-if="selectedImage && selectedImage.wl")
                MenuRangeSlider(
                  v-model="windowLevelData",
                  :min="allWindowLevel.min",
                  :max="allWindowLevel.max",
                  :step="1",
                  title="Window",
                  iconMinus="mdi-minus",
                  iconPlus="mdi-plus"
                )
                v-layout(justify-center, class="transparent")
                  v-btn(x-small, color="primary", class="mb-2", style="margin: 0.1em", @click="onWindowLevelSoftTissue") Soft tissue
                  v-btn(x-small, color="primary", class="mb-2", style="margin: 0.1em", @click="onWindowLevelBones") Bone
                  v-btn(x-small, color="primary", class="mb-2", style="margin: 0.1em", @click="onWindowLevelLungs") Lung

          div.group Options

          v-list.py-1(dense,nav)
            MenuItem(v-if="selectedImage && remote", icon="mdi-content-save-edit-outline", title="Submit to server", @click="saveAnnotationToServer()", :disabled="mode === 'form' && veeErrors.items.length > 0")
            MenuItem(v-if="selectedImage && remote", icon="mdi-content-save-edit-outline", title="Save for later", @click="saveAnnotationToServer({ saveOnly: true })", :disabled="mode === 'form' && veeErrors.items.length > 0")
            MenuItem(v-if="remote", icon="mdi-debug-step-over", title="Skip", @click="skipAnnotation()", :disabled="mode === 'form' && veeErrors.items.length > 0")
            //MenuPrevNext(v-if="remote", @prev="onLoadFromServer('back')", @next="onLoadFromServer('next')")
            MenuItem(v-if="debug && selectedImage && mode === 'segmentation'", icon="mdi-content-save-edit-outline", title="Save to disk", @click="saveAnnotationToDisk()")
            MenuItem(v-if="debug", icon="mdi-cloud-download-outline", title="Load from server", @click="onLoadFromServer('next')")
            MenuItemFileInput(v-if="debug", icon="mdi-file-upload-outline", title="Load from file", @change="onLoadFromDisk")
            MenuItemFileInput(v-if="debug && selectedImage && mode === 'segmentation'", icon="mdi-file-upload-outline", title="Load mask from file", @change="onLoadMaskFromDisk")
            MenuItem(icon="mdi-logout", title="Logout", @click="onLogout")

      v-main(class="editor-content")
        v-container(fluid, class="editor-container pa-0")
          v-layout(column, fluid class="editor-main-window")
            v-flex(class="editor-canvas-wrapper", @mousemove="onZoomMouseMove", @mouseleave="inRMBZoomMode = false")
              //video(v-if= "mediaType === 'video'", :src="video.src", :style="dimenstionStyle", ref='videoPlayer', crossorigin="anonymous", @timeupdate="onVideoTimeUpdate", @pause="onVideoPause")
              video(v-if= "mediaType === 'video'", :src="video.src", :width="canvasSize.width", :height="canvasSize.height", :style="dimenstionStyle", ref='videoPlayer', crossorigin="anonymous", @timeupdate="onVideoTimeUpdate", @pause="onVideoPause")

              canvas.editor-canvas(:width='canvasSize.width', :height='canvasSize.height', :style='mainCanvasStyle', ref='canvas')
              canvas.magic-wand-canvas(:width='canvasSize.width', :height='canvasSize.height', :style='`transform: scale(${this.scale / 100})`', ref='magicWandCanvas')
              // Tab index allows to call a .focus() method on canvas
              canvas.cursor-canvas(id='cursor-canvas', :width='canvasSize.width', :height='canvasSize.height', :class="canvasClass", :style="cursorCanvasStyle", ref='cursorCanvas', tabindex="1") 

            canvas(v-if="mediaType === 'video' && videoDebugMode", id="canvasOutput", :width="canvasSize.width", :height="canvasSize.height", :style="dimenstionStyle")
            ImageSelector(v-if="mediaType === 'image' && images.length > 1", :selectedIndex="selectedIndex")

      // AmazonLogin component provided by saliency-client package
      AmazonLogin(ref="AmazonLogin")

    OverlaySpinner(v-if="busy")
</template>

<script>
/**
 * @module Annotator
 */
import { cloneDeep, isEqual, delay } from 'lodash'
import dwv from 'dwv'
import 'magic-wand-js/js/magic-wand.js'

import { apiError, apiSuccess } from '@/util/ErrorMessage'
import { mapState, mapActions, mapMutations } from 'vuex'
import MenuBoxLinearCopy from '@/components/Sidebar/MenuBoxLinearCopy'
import MenuItem from '@/components/Sidebar/MenuItem'
import MenuPrevNext from '@/components/Sidebar/MenuPrevNext'
import MenuTool from '@/components/Sidebar/MenuTool'
import MenuItemFileInput from '@/components/Sidebar/MenuItemFileInput'
import MenuSlider from '@/components/Sidebar/MenuSlider'
import MenuRangeSlider from '@/components/Sidebar/MenuRangeSlider'
import SegmentationClassTool from '@/components/Sidebar/SegmentationClassTool'
import FormMode from '@/components/FormMode'
import VideoNavigation from '@/components/Sidebar/VideoNavigation'

import ImageSelector from '@/components/ImageSelector'
import ExportMixin from '@/mixins/ExportMixin.js'
import KeyMouseHelper from '@/mixins/KeyMouseHelper.js'
import DebugPanel from '@/components/DebugPanel'
import OverlaySpinner from '@/components/UI/OverlaySpinner'
import { setURLParam } from '@/util/AnnotatorUtil.js'

import Tool from '@/components/Tools/Tool.js'
import BrushTool from '@/components/Tools/Brush.js'
import PolyTool from '@/components/Tools/Poly.js'
import FillTool from '@/components/Tools/Fill.js'
import MagicWandTool from '@/components/Tools/MagicWand.js'
import BoxTool from '@/components/Tools/Box.js'
import LineTool from '@/components/Tools/Line.js'

import { FileDownloadMixin } from 'saliency-client'

import LucasKanadeOpenCVCopier from '@/components/Copiers/LucasKanadeOpenCV'
import LinearCopier from '@/components/Copiers/Linear'

/**
 * @vue-computed {Array.<String>} canvasClass - Returns classes for cursor canvas used to set custom cursors over drawing area
 * @vue-computed {Object} cursorCanvasStyle - Returns canvas style for cursor canvas which is top canvas
 * @vue-computed {Object} canvasSize - Returns selected image/canvase size as object with width and height fields
 * @vue-computed {Image} canvasImage - Returns selected image object or ''
 * @vue-computed {Boolean} debug - Debug variable from env var VUE_APP_DEBUG (.env file)
 * @vue-computed {Array} selectedAnnotations - Returns array of annotations for selected image or []
 * @vue-computed {Tool} activeToolObject - Returns Tool class instance for currently selected tool
 * 
 * @vue-data {Boolean} updateRedrawAll=false - control where we need to redraw all annotations or not
 * @vue-data {Array} toolClass - Maps tool name to proper css class used by canvasClass
 * @vue-data {Object} current - current figure drawing state
 * @vue-data {Number} scale=100 - image and annotation scaling
 * @vue-data {Array} tools - All tools class instances
 */

export default {
	name: 'Annotator',
	data: () => {
		return {
      // Main canvas
      canvas: null,
			canvasCtx: null,
			// Magic wand canvas which is on top of main canvas
			mwCanvas: null,
			mwCanvasCtx: null,
			// cursor canvas which is on top of all
			cursorCanvas: null,
			cursorCanvasCtx: null,
      // Canvas for temp drawing
      tmpCanvas: null,
      tmpCanvasCtx: null,
      // Temp canvas for 
      mwTmpCanvas: null,
      mwTmpCanvasCtx: null,
      // Params
      scale: 100,
      // Tools
			brushAlpha: .5,
      brushWidth: 60,
			eraserColor: '#ffffff',  
      cursorModifierClass: '',
			
			updateRedrawAll: false,

      toolClass: [
        { tool: 'pointer', class: 'tool-pointer' }, // for form mode
        { tool: 'brush', class: 'tool-brush' },
        { tool: 'eraser', class: 'tool-eraser' },
        { tool: 'poly', class: 'tool-poly-line' },
        { tool: 'fill', class: 'tool-fill' },
				{ tool: 'magicwand', class: 'tool-magic-wand' },
				{ tool: 'box', class: 'tool-box' },
        { tool: 'line', class: 'tool-line'},
      ],
			current: {
				isDrawing: false,
				mouseButtonHold: false, // for new poly tool
				figure: null,
				x: false,
				y: false
			},			
      // Bounding box
      // Mouse cursor is over this figure
      boxOverFigure: null,
      boxSelected: null,
      // Figure part: inner, corner, edge
			boxOverFigurePart: '',
      lineOverFigure: null,
      lineSelected: null,
      lineOverFigurePart: '',
			// Map to convert fig part to cursor
      partToCursor: new Map([
        ['inner', 'grab'],
        ['top-left', 'nw-resize'],
        ['bottom-left', 'sw-resize'],
        ['top-right', 'ne-resize'],
        ['bottom-right', 'se-resize'],
        ['left', 'ew-resize'],
        ['right', 'ew-resize'],
        ['top', 'ns-resize'],
        ['bottom', 'ns-resize'],
        ['start', 'move'],
        ['end', 'move']
      ]),
      boxCopyFrames: 5,

      tools: [],
      playingVideo: false,
      playVideoTimer: null,

      frameRenderTimeout: 100, // msec
      // Start or singe
      boxRemembered: null,
      boxRememberedFrame: -1,
      // End for two-box mode
      boxRemembered2: null,
      boxRememberedFrame2: -1,

      inRMBZoomMode: false,
      RMBZoomY: 0,
      minZoom: 10,
      maxZoom: 500,
      saveInterval: null
    }
  },
	components: {
    MenuBoxLinearCopy,
    MenuItem,
    MenuPrevNext,
    MenuTool,
    MenuItemFileInput,
    MenuSlider,
    MenuRangeSlider,
    ImageSelector,
    SegmentationClassTool,
		OverlaySpinner,
    FormMode,
    DebugPanel,
    VideoNavigation
  },
  mixins: [
    ExportMixin,
    KeyMouseHelper,
    FileDownloadMixin
  ],
  computed: {
    ...mapState({
      busy: state => state.busy,
      mediaType: state => state.images.mediaType,
      mode: state => state.images.mode,
      remote: state => state.images.remote,
			segmentation: state => state.images.segmentation,
			form: state => state.images.form,
			boundingBox: state => state.images.boundingBox,
      line: state => state.images.line,
      images: state => state.images.images,
      multilabel: state => state.images.multilabel,      
			activeTool: state => state.images.activeTool,
			brushColor: state => state.images.brushColor,
			selectedIndex: state => state.images.selectedIndex,
      selectedImage: state => state.images.selectedImage,
      originalName: state => state.images.originalName,
      video: state => state.images.video,
      allWindowLevel: state => state.images.windowLevel
    }),
    /**
     * Returns classes used to set custom cursors over drawing area
     */
    canvasClass () {
      return [this.toolClass.find(tc => tc.tool === this.activeTool).class] + this.cursorModifierClass
    },
    dimenstionStyle () {
      return {
        'transform': `scale(${this.scale / 100})`
      }
    },
    mainCanvasStyle () {
      let style = { ...this.dimenstionStyle }

      if (this.mediaType === 'image') {
        style['background-image'] = `url(${this.canvasImage.src})`
      }

      return style
    },
    cursorCanvasStyle () {
      let style = { ...this.dimenstionStyle }

      switch (this.activeTool) {
        case 'box': {
          if (!this.current.isDrawing && this.boxOverFigure && this.boxOverFigurePart) {
            style.cursor = `${this.partToCursor.get(this.boxOverFigurePart)} !important`
          } 
          
          break
        }
        case 'line': {
          if (!this.current.isDrawing && this.lineOverFigure && this.lineOverFigurePart) {
            style.cursor = `${this.partToCursor.get(this.lineOverFigurePart)} !important`
          }

          break
        }
      }

      return style
    },
    canvasSize () {
      return this.selectedImage
        ? this.mediaType === 'video'
          // For video - take size from video metadata
          ? { width: this.video.width, height: this.video.height }
          // For images - we have size for each image/slice/frame
          : { width: this.selectedImage.image.width, height: this.selectedImage.image.height }
        : { width: 0, height: 0 }
    },
    canvasImage () {
      return this.selectedImage
        ? this.selectedImage.image
        : ''
    },
    debug () {
      return process.env.VUE_APP_DEBUG
    },
    selectedAnnotations () {
      return this.images.length > 0
        ? this.multilabel
          ? this.images[this.selectedIndex].annotations
          : this.images[0].annotations          
        : []
    },
    activeToolObject () {
      return this.tools.find(tool => tool.name === this.activeTool)
    },
    videoDebugMode () {
      return process.env.VUE_APP_VIDEO_DEBUG === 'true'
    },
    windowLevelData: {
      get () {
        return this.selectedImage && this.selectedImage.wl
          ? [this.allWindowLevel.a, this.allWindowLevel.b]
          : [0, 0]
      },
      set (value) {
        this.updateImageWindowLevel({
          index: this.selectedIndex,
          a: value[0],
          b: value[1]
        })

        this.setImageWindowLevel()
      }
    },
    videoLength () {
      const date = new Date(this.video.duration * 1000)
      
      return `${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`
    }
  },
  methods: {
    ...mapMutations({
      setBusy: 'setBusy',
      setBrushColor: 'images/setBrushColor',
      reset: 'images/reset',
      updateImageWindowLevel: 'images/updateImageWindowLevel'

    }),
    ...mapActions({
      preloadFromServer: 'images/preloadFromServer',
      postloadFromServer: 'images/postloadFromServer',
			loadFromDisk: 'images/loadFromDisk',
      loadMaskFromDisk: 'images/loadMaskFromDisk',
      updateFileWindowLevel: 'images/updateFileWindowLevel',
      logout: 'auth/logout',
      setSelected: 'images/setSelected'      
    }),
    onLogout () {
      this.logout()
      this.$router.push('/login')
    },
    onLoadFromDisk (event) {
      this.loadFromDisk(event.target.files)
    },
    getColors (){
      let colors = null
      
      if (this.mode == "box")
        colors = this.boundingBox.colors
        
      if (this.mode == "segmentation")
        colors = this.segmentation.colors

      if (this.mode == "line")
        colors = this.line.colors
        
      return colors.slice() // copy array
    },
		onUndo () {
			this.current.figure = false
			this.selectedAnnotations.pop()
      this.forceRedraw()
		},
		/**
     * Mouse events handler, calls active tool handler
     * @param {MouseEvent} e - mouse event
     */
		mouseHandler (e) {
			if (this.selectedImage && this.maskVisible) {
        let rect = this.canvas.getBoundingClientRect()

        this.current.x = Math.round((e.clientX - rect.left) / (this.scale / 100))
        this.current.y = Math.round((e.clientY - rect.top) / (this.scale / 100))

        switch (e.type) {
          case 'mousedown': {
            if (e.button === 2 && !this.toolObjectByName(this.activeTool).usesRMB) {
              this.RMBZoomY = e.y
              this.inRMBZoomMode = true
            }

            break
          }

          case 'mouseup': {
            if (this.inRMBZoomMode) {
              this.inRMBZoomMode = false
            }

            break
          }

          case 'contextmenu': {
            e.preventDefault()
            break
          }
        }

        this.toolObjectByName(this.activeTool).handleMouse(e)
				this.redrawCanvas()
			}
		},
    onZoomMouseMove (e) {
      switch (e.type) {
        case 'mousemove': {
          if (this.inRMBZoomMode) {
            const delta = this.RMBZoomY - e.y
            const newScale = this.scale - delta

            if (delta > 0 && newScale <= this.maxZoom && Math.abs(this.scale - newScale) >= 10) {
              this.RMBZoomY = e.y
              this.scale = newScale                
            } else if (delta < 0 && newScale >= this.minZoom && Math.abs(this.scale - newScale) >= 10) {          
              this.RMBZoomY = e.y
              this.scale = newScale
            }

            e.preventDefault()
          }

          break
        }
      }
    },
		forceRedraw () {
			this.updateRedrawAll = true
			this.redrawCanvas()
    },
    /**
     * Redraw cursor and annotations if needed
     * Annotations redraw controlled by this.updateRedrawAll variable
     */
		redrawCanvas () {
			if (this.selectedImage) {				
				// clear cursor canvas in any case
				this.cursorCanvasCtx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height)

				if (this.current.isDrawing) {
					switch (this.activeTool) {
						// This allows us to draw eraser cursor during erasing
						// otherwise there will be no cursor at all
						case 'eraser': {
							this.drawBrushCursor()
							break
						}
						case 'poly': {
              if (this.current.isDrawing) {
                this.cursorCanvasCtx.globalAlpha = this.brushAlpha
                this.cursorCanvasCtx.strokeStyle = this.brushColor
                this.cursorCanvasCtx.lineWidth = this.current.figure.lineWidth

                // For unfinished poly-line figure we redraw only unfinished part on cursor canvas
                this.drawAnnotationFigure(this.cursorCanvasCtx, this.current.figure, this.brushAlpha)

                // Now draw last line which follow cursor
                this.cursorCanvasCtx.beginPath()
                this.cursorCanvasCtx.moveTo(this.current.figure.points[this.current.figure.points.length-1][0], 
                                            this.current.figure.points[this.current.figure.points.length-1][1])
                this.cursorCanvasCtx.lineTo(this.current.x, this.current.y)
                this.cursorCanvasCtx.stroke()
              }

							break
            }
					}
				} else {
          if (this.current.x) {
            switch (this.activeTool) {
              // draw cursor for brush and eraser on dedicated cursor canvas              
              case 'brush':
              case 'eraser': {
                this.drawBrushCursor()
                break
              }
            }
          }
        }
        
        // Redraw only when this is really needed
        if (this.updateRedrawAll || this.current.figure) {	
          this.drawAllAnnotationsDisplay()
        }
			}
    },
    /**
     * Draw custom cursor for brush & eraser tools
     */
		drawBrushCursor () {
			this.cursorCanvasCtx.globalAlpha = this.brushAlpha
			this.cursorCanvasCtx.fillStyle = this.activeTool === 'brush' ? this.brushColor : this.eraserColor
			this.cursorCanvasCtx.beginPath()
			this.cursorCanvasCtx.arc(this.current.x, this.current.y, this.brushWidth / 2, 0, Math.PI * 2)
			this.cursorCanvasCtx.fill()
    },
    /**
     * Calls drawAllAnnotations with standard parameters to draw on display
     */
    drawAllAnnotationsDisplay () {
      // Use currently selected image annotations stack
      this.drawAllAnnotations(this.canvasCtx, this.selectedIndex, this.brushAlpha, true)
    },
    /**
     * Draw all figures from desired image
     * @param {CanvasRenderingContext2D} canvasCtx - target canvas
     * @param {Number} imageIndex - image index
     * @param {Number} alpha - alpha level
     * @param {Boolean} clearContext - clear target canvas before drawing, only needed for display
     */
		drawAllAnnotations (canvasCtx, imageIndex, alpha, clearContext) {
			// Fist draw on temp canvas with alpha 1
			this.tmpCanvasCtx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height)
			this.tmpCanvasCtx.lineCap = 'round'
			this.tmpCanvasCtx.lineJoin = 'round'

      // First draw preloaded mask
      let preloadedMask = this.multilabel ? this.selectedImage.mask : this.images[0].mask

			if (preloadedMask) {        
				this.tmpCanvasCtx.globalCompositeOperation = 'source-over'
				this.tmpCanvasCtx.drawImage(preloadedMask, 0, 0)
			}

      // Draw all figures
      // For !multilabel mode use first figure as source
      let figures = this.images[this.multilabel ? imageIndex : 0].annotations

			for (let i = 0; i < figures.length; i++) {
				this.drawAnnotationFigure(this.tmpCanvasCtx, figures[i], 1)
			}

			if (this.current.figure) {
				this.drawAnnotationFigure(this.tmpCanvasCtx, this.current.figure, 1)
			}

      // redraw to main canvas with uniform alpha
			if (clearContext) {
				canvasCtx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height)
			}      

			// Now copy to main canvas with desired alpha
			canvasCtx.globalAlpha = alpha
			canvasCtx.drawImage(this.tmpCanvas, 0, 0, this.canvasSize.width, this.canvasSize.height)		
			this.updateRedrawAll = false
    },
    /**
     * Draw single figure
     * @param {CanvasRenderingContext2D} canvasCtx - target canvas context
     * @param {Object} figure - figure to draw
     * @param {Number} alpha - alpha level
     */
		drawAnnotationFigure (canvasCtx, figure, alpha = 1) {		
			// Unlike Chrome & Opera, Firefox does not understand 'normal', so we need to user 'source-over'
			canvasCtx.globalCompositeOperation = figure.tool === 'eraser' ? 'destination-out' : 'source-over'
      this.toolObjectByName(figure.tool).drawFigure(canvasCtx, figure, alpha)
    },
		async onLoadMaskFromDisk (event) {
			await this.loadMaskFromDisk({ index: this.selectedIndex, file: event.target.files[0]})
			// to ensure that preloaded mask is drawn
			this.drawAllAnnotationsDisplay()
    },
    /**
     * Load image from server
     * @param {String} mode - posstible values: 'back', 'current', 'next'
     */
		async onLoadFromServer (mode = 'next') {
      // reset interval if any
      if (this.saveInterval) {
        window.clearInterval(this.saveInterval)
      }

      this.setBusy(true)

      try {
        // 1. Request file info
        let response = await this.preloadFromServer(mode)

        if (response.data.count === 0) {
          apiSuccess('There are no more images')
        } else {
          let file = response.data.results[0]

          // 2. Download file from different sources:
          //    - S3 Amazon (auth with keyID/secret key)
          //    - Other sources without authentication
          let responseData = await this.downloadFile(file.datapoint.file, apiError)

          // 3. Process downloaded file
          await this.postloadFromServer({ file, binary: responseData.data, mode, downloadFile: this.downloadFile })  

          // Set next & prev request URL
          setURLParam(response.data.next, 'images/setNextURL')
          setURLParam(response.data.previous, 'images/setPrevURL')

          // To ensure that preloaded mask is drawn
          this.drawAllAnnotationsDisplay()

          this.saveInterval = window.setInterval(() => {
            this.saveIntervalHandler()
            // 10 mins
          }, 1000 * 60 * 10)
        } 
      } catch (error) {
        // reset all auth
        this.reset()

        if (error === 'Cancelled by user') {
          this.onLogout()
        } else {
          apiError(error)
        }
      }
      
      this.saveAnnotationToServer({
          saveOnly: true,
          saveSilent: true
      })

      this.setBusy(false)
    },
    onCopyLastMask () {
      // assert multilabel multi image mode (otherwise the button wouldn't show up)
      this.images[this.selectedIndex].annotations = this.images[this.selectedIndex-1].annotations.map(a => ({
          ...a
        }))
      this.images[this.selectedIndex].mask = this.images[this.selectedIndex-1].mask

      this.forceRedraw()
    },
    onDeleteBox () {
      this.selectedImage.annotations.splice(this.selectedImage.annotations.indexOf(this.boxSelected), 1)
      this.boxSelected = null
      this.onCancelBoxCopy()
      this.drawAllAnnotationsDisplay()
    },
    onSetCopyBox (start) {
      if (start || (start === undefined && this.boxRememberedFrame < 0)) {
        this.boxRemembered = this.boxSelected
        this.boxRememberedFrame = this.selectedIndex
      } else {
        this.boxRemembered2 = this.boxSelected
        this.boxRememberedFrame2 = this.selectedIndex
      }


    },
    onResetBox (start = true) {
      if (start) {
        this.boxRemembered = null
        this.boxRememberedFrame = -1
      } else {
        this.boxRemembered2 = null
        this.boxRememberedFrame2 = -1
      }
    },
    async onShowBox (start = true) {
      if (start) {
        await this.setSelected(this.boxRememberedFrame)
        this.boxSelected = this.boxRemembered
      } else {
        await this.setSelected(this.boxRememberedFrame2)
        this.boxSelected = this.boxRemembered2
      }
    },
    onCancelBoxCopy () {
      this.onResetBox(true)
      this.onResetBox(false)
    },
    onCopyBoxLucasKanade () {
      this.onCopyBoxClass(LucasKanadeOpenCVCopier)
    },
    onCopyBoxLinear () {
      this.onCopyBoxClass(LinearCopier)
    },
    async onCopyBoxClass (copierClass) {
      this.setBusy(true)

      try {        
        let copier = new copierClass(this, this.videoDebugMode, this.$refs.videoPlayer)

        const startFrame = this.boxRememberedFrame + 1
        const endFrame = copier.requireEndBox
          ? this.boxRememberedFrame2 - 1
          : this.selectedIndex

        // Clone it before starting animation
        let prevBox = this.boxRemembered

        await new Promise(resolve => {
          if (copier.useVideo) {
            this.onNavigateVideo(this.boxRememberedFrame)
          }

          window.setTimeout(async () => {
            if (copier.requireEndBox) {
              copier.start(prevBox.points, this.boxRemembered2.points, this.boxRememberedFrame2 - this.boxRememberedFrame)
            } else {
              copier.start(prevBox.points)              
            } 

            for (let i = startFrame; i <= endFrame; i++) {
              await new Promise(resolve => {
                // change frame
                if (copier.useVideo) {
                  this.onNavigateVideo(i)
                }

                // wait to redraw and process
                window.setTimeout(() => {
                  const vector = copier.captureFrame(prevBox.points)
                  
                  let frameAnnotations = this.images[i].annotations
                  
                  let boxCopy = cloneDeep(prevBox)

                  // modify using received vectors                  
                  boxCopy.points[0][0] += vector.x1
                  boxCopy.points[0][1] += vector.y1
                  boxCopy.points[1][0] += vector.x2
                  boxCopy.points[1][1] += vector.y2

                  // Check if frame already have box with exactly the same parameters
                  if (!frameAnnotations.find(a => isEqual(a, boxCopy))) {
                    frameAnnotations.push(boxCopy)
                  }                  

                  prevBox = boxCopy

                  resolve()
                }, copier.useVideo ? this.frameRenderTimeout : 0)
              })
            }

            // ensure new annotation is displayed
            this.drawAllAnnotationsDisplay()

            resolve()
          }, this.frameRenderTimeout)
        })

        // Reset all box selections when done
        this.onCancelBoxCopy()
      } finally {
        this.setBusy(false)
      }
    },
    onDeleteLine () {
      this.selectedImage.annotations.splice(this.selectedImage.annotations.indexOf(this.lineSelected), 1)
      this.lineSelected = null
      this.drawAllAnnotationsDisplay()
    },
    toolObjectByName (name) {
      return this.tools.find(tool => tool.name === name)
    },
    async onNavigateVideo (value) {
      this.$refs.videoPlayer.currentTime = value * this.video.interval
      await this.setSelected(value)
    },
    async onVideoTimeUpdate () {
      if (this.$refs.videoPlayer && this.playingVideo) {
        // Stick video to the closes frame
        let closestFrame = Math.floor(this.$refs.videoPlayer.currentTime / this.video.interval)

        if (closestFrame !== this.selectedIndex) {
          await this.setSelected(closestFrame)
        }
      }
    },
    onVideoPause () {
      this.onVideoTimeUpdate()
      this.playingVideo = false
    },
    async onChangeWindowLevel () {
      // calculate center and width from min and max
      // update 2d image with new values
      await this.updateFileWindowLevel(this.selectedIndex)

      // force redraw
      this.forceRedraw()
    },
    async onWindowLevelBones () {
      this.updateImageWindowLevel({
        index: this.selectedIndex,
        a: -500,
        b: 1300
      })

      await this.onChangeWindowLevel()
    },
    async onWindowLevelLungs () {
      this.updateImageWindowLevel({
        index: this.selectedIndex,
        a: -1500,
        b: 500
      })

      await this.onChangeWindowLevel()
    },
    async onWindowLevelSoftTissue () {
      this.updateImageWindowLevel({
        index: this.selectedIndex,
        a: -175,
        b: 275
      })

      await this.onChangeWindowLevel()
    },
    setImageWindowLevel () {
      delay((a, b) => { 
        if (a === this.selectedImage.wl.a && b === this.selectedImage.wl.b) {
          this.onChangeWindowLevel()
        }          
      }, 50, this.selectedImage.wl.a, this.selectedImage.wl.b)
    },
    saveIntervalHandler () {
      if (this.selectedImage && this.remote && this.mode !== 'form' && this.veeErrors.items.length === 0) {
        this.saveAnnotationToServer({
          saveOnly: true,
          saveSilent: true
        })
      }
    }
	},
	watch: {
		/**
		 * Reset all unfinished drawings on tool change
     * @param {String} newVal - new tool name
     * @param {String} oldVal - previous tool name
		 */
		activeTool (newVal, oldVal) {
			this.current.figure = null
      this.toolObjectByName(oldVal).reset()
		},
		/**
		 * Monitor selected image changes
		 * We use selectedImage for this because selectedIndex may not change when new image is loaded
     * @param {Object} newVal - new value of this.selectedImage
		 */
		async selectedImage (newVal) {
			if (newVal && newVal.loaded) {
        // Reset selection
        if (this.mode === 'box') {
          this.boxSelected = null
        }

				// Finish current drawing if we have one
				if (this.selectedImage && this.current.figure) {
					this.toolObjectByName(this.activeTool).finishDrawing()
        }
        
        this.$refs.cursorCanvas.focus()

				this.tmpCanvas.width = this.canvasSize.width
        this.tmpCanvas.height = this.canvasSize.height
        
				this.mwTmpCanvas.width = this.canvasSize.width
				this.mwTmpCanvas.height = this.canvasSize.height

        this.forceRedraw()
			}
    },
    playingVideo (value) {
      if (value) {
        // Start playback
        this.$refs.videoPlayer.play()

        this.playVideoTimer = setInterval(() => {
          this.onVideoTimeUpdate()
        }, 50)
      } else {
        clearInterval(this.playVideoTimer)
        // Stop playback        
        this.$refs.videoPlayer.pause()
      }
    }
  },
	mounted () {
    // More time for per-frame delay for FireFox
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.frameRenderTimeout = 200
    }

    // Global dwv init
    dwv.gui.getElement = dwv.gui.base.getElement
    dwv.gui.displayProgress = function () {}
    
    // Image decoders (for web workers)
    dwv.image.decoderScripts = {
       "jpeg2000": "node_modules/dwv/decoders/pdfjs/decode-jpeg2000.js",
       "jpeg-lossless": "/decoders/rii-mango/decode-jpegloss.js", //node_modules/dwv/decoders/rii-mango/decode-jpegloss.js",
       "jpeg-baseline": "node_modules/dwv/decoders/pdfjs/decode-jpegbaseline.js"
    };
    
    // we need this only one time, no need to make this assignments each time we call reset canvas
    this.canvas = this.$refs.canvas
		this.canvasCtx = this.canvas.getContext('2d')

    // temporary canvas, used to draw figures before copying to main canvas
		this.tmpCanvas = document.createElement('canvas')
		this.tmpCanvasCtx = this.tmpCanvas.getContext('2d')

    this.mwTmpCanvas = document.createElement('canvas')
    this.mwTmpCanvasCtx = this.mwTmpCanvas.getContext('2d')

		this.mwCanvas = this.$refs.magicWandCanvas
		this.mwCanvasCtx = this.mwCanvas.getContext('2d')

		this.cursorCanvas = this.$refs.cursorCanvas
		this.cursorCanvasCtx = this.cursorCanvas.getContext('2d')

    // Create tools
    this.tools = [
      // Pointer, default tool that does nothing
      new Tool(this, 'pointer'),
      // Brush
      new BrushTool(this, false, 'b'),
      // Eraser
      new BrushTool(this, true, 'e'),
      new PolyTool(this),
      new FillTool(this),
      new MagicWandTool(this, this.mwCanvas, this.mwCanvasCtx, this.mwTmpCanvas, this.mwTmpCanvasCtx),
      new BoxTool(this),
      new LineTool(this)
    ]

		// All events on cursorCanvas since it is on top
    this.cursorCanvas.onmousemove = this.mouseHandler
    this.cursorCanvas.onmousedown = this.mouseHandler
    this.cursorCanvas.onmouseup = this.mouseHandler
    this.cursorCanvas.onmouseout = this.mouseHandler
    this.cursorCanvas.onmouseover = this.mouseHandler
    this.cursorCanvas.onclick = this.mouseHandler
    this.cursorCanvas.ondblclick = this.mouseHandler    
    this.cursorCanvas.oncontextmenu = this.mouseHandler
    document.getElementsByTagName('body')[0].onkeypress = this.onKeyPress
    document.getElementsByTagName('body')[0].onkeydown = this.onKeyDown
    document.getElementsByTagName('body')[0].onkeyup = this.onKeyUp
    // We use this method to add event listener to avoid this error:
    // [Intervention] Unable to preventDefault inside passive event listener due to target being treated as passive.
    //document.getElementById('cursor-canvas').addEventListener('mousewheel', this.onMouseWheel, { passive: false })
    document.getElementsByTagName('body')[0].addEventListener('wheel', this.onMouseWheel, { passive: false })
    document.getElementsByTagName('body')[0].addEventListener('mousemove', this.onMouseMove)
    document.getElementsByTagName('body')[0].addEventListener('mousedown', this.onMouseDown)
    document.getElementsByTagName('body')[0].addEventListener('mouseup', this.onMouseUp)
    document.getElementsByTagName('body')[0].addEventListener('mouseout', this.onMouseOut)
    // Auto load first image
    this.onLoadFromServer(this.$route.params.id ? this.$route.params.id : '')
  },
  beforeDestroy () {
    if (this.saveInterval) {
      window.clearInterval(this.saveInterval)
    }

    if (this.$refs.videoPlayer && this.playingVideo) {
      clearInterval(this.playVideoTimer)
      this.$refs.videoPlayer.pause()
    }

    // destroy magic wand border timer
    this.toolObjectByName('magicwand').dispose()
  }
}
</script>

<style lang="stylus">
@import "../assets/vars"

.editor-content
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0

  .editor-container
    height: 100%

    .editor-main-window
      height: 100%

      & .editor-canvas-wrapper
        flex: 1 1
        background-color: #4B0F42
        overflow: auto
        position: relative

        canvas, video
          position: absolute
          top: 0
          left: 0
          transform-origin: left top

          &:focus
            outline: none !important

        & .editor-canvas {
          background-size: cover
          transition: background-image .15s ease-in-out
          flex-shrink: 0
        }  

      & .image-selector
        background-color: red
        min-height: 150px
        flex: 0

      // Tools classes
      & .tool-brush, & .tool-eraser
        cursor: none
      & .tool-poly-line
        cursor: crosshair
      & .tool-poly-line-dot
        cursor: url('~@/assets/dot.cur'), nw-resize !important
      & .tool-fill
        cursor: url('~@/assets/fill.cur'), pointer !important
      & .tool-magic-wand
        cursor: url('~@/assets/magicwand.cur'), nw-resize !important
      & .tool-box
        cursor: crosshair
      & .tool-line
        cursor: crosshair

.editor-sliders
  & .v-input__prepend-outer
    margin-right: 0px !important;

  & .v-input__append-outer
    margin-left: 0px !important;

div#logo
  margin-left: 0.25em;
  margin-top: 0.55em;
  margin-bottom: 0.3em;
div#logo img
  width: 68%;
  
div.center
  text-align: center

.v-navigation-drawer
  background-color: #212120 !important;
  border-right: 0 !important;

div.group
  background-color: #151515;
  text-align: center;
  padding: 4px 0.5em 4px 0.5em;
  font-size: 0.9em;
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;

body, .layout
  background-color: #f4f3ef; 

.light-bg
  background-color: #f4f3ef; 

v-messages
  display: none !important

.inshadow
  -moz-box-shadow:    inset 0 0 20px black
  -webkit-box-shadow: inset 0 0 20px black
  box-shadow:         inset 0 0 20px black
  overflow-y: auto !important
</style>

