<template>
  <div class="menu-box-linear-copy py-2">
    <MenuBoxSelectedInfo
      title="Start box"
      :boxSelected="boxSelected"
      :value="boxRemembered"
      :frame="boxRememberedFrame"
      :setDisabled="boxSelectedIndex >= (boxRememberedFrame2 - 1)"
      @show="$emit('show', true)"
      @reset="$emit('reset', true)"
      @set="$emit('set', true)"/>

    <MenuBoxSelectedInfo
      title="End box"
      :boxSelected="boxSelected"
      :value="boxRemembered2"
      :frame="boxRememberedFrame2"
      :setDisabled="boxSelectedIndex <= (boxRememberedFrame + 1)"
      @show="$emit('show', false)"
      @reset="$emit('reset', false)"
      @set="$emit('set', false)"/>
  </div>
</template>

<script>
import MenuBoxSelectedInfo from './MenuBoxSelectedInfo'

export default {
  name: 'MenuBoxLinearCopy',
  components: {
    MenuBoxSelectedInfo
  },
  props: {
    boxSelected: Object,
    boxSelectedIndex: Number,
    boxRemembered: Object,
    boxRememberedFrame: Number,
    boxRemembered2: Object,
    boxRememberedFrame2: Number
  }
}
</script>

<style lang="scss">
.menu-box-linear-copy {
  display: flex;

  > * {
    width: 50%;
  }
}
</style>