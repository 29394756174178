import axios from 'axios'
import AWS from 'aws-sdk'

import '@/assets/main.styl'

import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store/store.js'
import vuetify from './plugins/vuetify'

import VeeValidate from "vee-validate"
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
})

import Toasted from 'vue-toasted'
Vue.use(Toasted, { 
  position: 'bottom-center', 
  duration: 10000,
  className: 'vue-toasted',
	// Show all toasts with Close button
  action : {
    text : 'Close',
    onClick : (e, toastObject) => {
        toastObject.goAway(0)
    }
  }
})

Vue.config.productionTip = false

if (!process.env.VUE_APP_API_HOST) {
    axios.defaults.baseURL = "https://" + window.location.hostname + ":8000/"
	//throw new TypeError("Saliency.ai API error: You have to define VUE_APP_API_HOST in .env. Copy .env-localhost or .env-devserver to .env")
}
else {
    axios.defaults.baseURL = process.env.VUE_APP_API_HOST
}

// Redirects to login page if API request failed due to invalid token
axios.interceptors.response.use(response => response,
  error => {
    if (error.response.status === 403) {
      store.dispatch('auth/logout')
      router.push('/login')
		}

		return Promise.reject(error.response)
  }
)

import SaliencyClient from 'saliency-client'
Vue.use(SaliencyClient, { store, axios, AWS })

// Check for token and auto login if any
store.dispatch('auth/checkToken')

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
