/** 
 * @module tools/Tool 
 */

/** 
 * Base class for all tools 
 */
class Tool {
  /**
   * Create tool
   * @constructor
   * @param {Object} annotator - Annotator vue component reference
   * @param {String} name - tool name
   */
  constructor (annotator, name, shortkey = '') {    
    this.name = name    
    this.annotator = annotator
    this.shortkey = shortkey
    // to avoid chaning this.current to this.annotator.current in whole code
    // we use this shorthand
    this.current = annotator.current
    // flag that this tool uses right mouse button
    this.usesRMB = false 
  }
  /**
   * Reset drawing state
   */
  reset () {
    this.current.isDrawing = false
  }
  /** Start new figure
   * @param {String} color - figure color
   * @param {Number} width - figure line width
   */
  startFigure (color, width) {
    this.current.figure = {
      tool: this.annotator.activeTool,
      color: color,
      points: [[
        this.current.x, 
        this.current.y
      ]]
    }
    
    if (width > 0) {
      this.current.figure.lineWidth = width
    }

    this.current.isDrawing = true
  }
  /**
   * Add point to current figure points array
   * @param {Number} x - point.x
   * @param {Number} y - point.y
   */
  addPoint (x, y) {          
    if ((this.current.figure.points.length === 0) || 
        (this.current.figure.points[this.current.figure.points.length-1][0] !== x) || 
        (this.current.figure.points[this.current.figure.points.length-1][1] !== y)) {
      this.current.figure.points.push([x, y])
    }
  }
  /**
   * Finish current figure drawing and move in into undo stack
   */
  finishDrawing () {
    this.annotator.selectedAnnotations.push(this.current.figure)
    this.annotator.updateRedrawAll = true
    this.current.figure = false
  }
}

export default Tool