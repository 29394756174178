<template lang="pug">
  v-list-item
    div.s-container
      div.label(v-if="title") {{ title }}
      div(class="slider-container")
        span(v-if="iconMinus", class="mdi", :class="iconMinus", @click="minusValue")
        v-slider(v-model="intValue", class="align-center", :min="min", :max="max", :step="step", hide-details, thumb-label, @change="$emit('change', $event)")
        span(v-if="iconPlus", class="mdi", :class="iconPlus", @click="plusValue")
</template>

<script>
export default {
  name: 'MenuSlider',
  props: {
    title: String,
    value: Number,
    min: Number,
    max: Number,
    step: Number,
    iconMinus: String,
    iconPlus: String
  },
  computed: {    
    intValue: {
      get () {
        return this.value
      },
      // Pass property back to parent, update v-model
      set (value) {
        this.$emit('input', value)
      }
    } 
  },
  methods: {
    // Check bounds and decrease value
    minusValue () {
      let newValue = this.value - this.step

      if (newValue >= this.min) {
        this.intValue = newValue
        this.$emit('change') // force redraw
      }
    },
    // Check bounds and increase value
    plusValue () {
      let newValue = this.value + this.step

      if (newValue <= this.max) {
        this.intValue = newValue
        this.$emit('change') // force redraw
      }
    }
  }
}
</script>

<style lang="scss">
.label {
  display: inline-block;
  width: 10em;
  font-size: 0.8em;
}
div.s-container {
  width: 100%;

  .slider-container {
    display: flex;
    flex-direction: flex-row;
    align-items: center;

    span.mdi {
      font-size: 24px;
      cursor: pointer;
    }
  }

}
</style>
