/**
 * Mixins that helps with handling keyboard shortcuts & mouse wheel
 * @mixin KeyMouseHelper
 */
import { mapMutations, mapActions, mapState } from 'vuex'
import { fillArray, reverseFillArray } from '@/util/Util.js'

export default {
  data () {
    return {
      sliceScrollTimeout: null,
      sliceScrollDelta: 0,
      maskVisible: true,
      changingImageInProgress: false,
      middleMouseNav: false,
      middleMouseNavStart: 0,
      middleMouseStartIndex: 0,
      upperYCheckpoints: [],
      lowerYCheckpoints: [],
      lastCheckedY: 0,
    }
  },
  computed: {
    ...mapState({
			activeTool: state => state.images.activeTool,
    }),
  },
  methods: {
		...mapMutations({
      setActiveTool: 'images/setActiveTool',
      setShiftStatus: 'images/setShiftStatus'
    }),
		...mapActions({
      setSelected: 'images/setSelected'      
    }),
    /**
     * Handles keyboard event for switching tools, undo, image changing
     * @param {KeyboardEvent} event - keyboard event
     * @memberof KeyMouseHelper
     */
    async onKeyPress (event) {
      let keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
      const labelIndex = keys.indexOf(event.key)

      if (labelIndex >= 0 && labelIndex < this.segmentation.colors.length) {
        this.setBrushColor(this.segmentation.colors[labelIndex].color)
      } else {
        const tool = this.tools.find(t => t.shortkey === event.key.toLowerCase())

        if (tool) {
          this.setActiveTool(tool.name)
        } else {          
          // Other keys
          switch (event.key) {
            // Undo special handling (since it is not a tool)
            case 'u': {
              this.onUndo()
              break
            }
            // '<' key
            case ',': {
              if (this.images.length > 0 && this.selectedIndex > 0) {
                await this.setSelected(this.selectedIndex - 1)
              }

              break
            }
            // '>' key
            case '.': {
              if (this.images.length > 0 && this.selectedIndex < (this.images.length - 1)) {
                await this.setSelected(this.selectedIndex + 1)
              }

              break
            }
          }
        }
      }
    },
    onKeyDown (event) {
      this.setShiftStatus(event.shiftKey)

      switch (event.key) {
        // '<' key        
        case 'ArrowUp':
        case 'ArrowLeft': {
          this.previousImage()
          break
        }
        // '>' key
        case 'ArrowDown':
        case 'ArrowRight': {
          this.nextImage()
          break
        }
        case 'T':
        case 't': {
          if (this.maskVisible) {
            this.canvasCtx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height)
            this.maskVisible = false
          }
          
          break
        }
        case 'Control':
          if (this.activeTool === 'poly') {
            !this.cursorModifierClass ? this.cursorModifierClass = '-dot' : this.cursorModifierClass = ''
          }
      }
    },
    onKeyUp (event) {
      this.setShiftStatus(event.shiftKey)

      if (!this.maskVisible) {
        this.drawAllAnnotationsDisplay()
        this.maskVisible = true
      }      
    },
    previousImage () {
      if (this.images.length > 0 && this.selectedIndex > 0) {
        if (this.changingImageInProgress) return

        this.changeImage(this.selectedIndex - 1)

        if (this.mediaType === 'video' && this.$refs.videoPlayer) {
          this.$refs.videoPlayer.currentTime = this.selectedIndex * this.video.interval
        }
      }
    },
    nextImage () {
      if (this.images.length > 0 && this.selectedIndex < (this.images.length - 1)) {
        if (this.changingImageInProgress) return

        this.changeImage(this.selectedIndex + 1)

        if (this.mediaType === 'video' && this.$refs.videoPlayer) {
          this.$refs.videoPlayer.currentTime = this.selectedIndex * this.video.interval
        }
      }
    },
    /**
     * Handles mouse wheel and change brush width (with Ctrl) or zoom
     * @param {MouseEvent} event - mouse event
     * @memberof KeyMouseHelper
     */
    onMouseWheel (event) {
      if (event.ctrlKey) {
        // Handle brush size
        //this.brushWidth = this.handleValueByWheel(this.brushWidth, 5, 100, 1, event)
        //this.forceRedraw()
      } else {
        // Slice scroll
        if (this.sliceScrollTimeout) {
          if (event.deltaY > 0) {
            this.sliceScrollDelta++  
          } else if (event.deltaY < 0) {
            this.sliceScrollDelta--
          }
        } else {
          this.sliceScrollDelta = event.deltaY > 0 ? 1 : -1

          this.sliceScrollTimeout = window.setTimeout(delta => {
            let newIndex = this.selectedIndex + Math.round(delta / 80)

            if (newIndex < 0) {
              newIndex = 0
            } else if (newIndex >= this.images.length) {
              newIndex = this.images.length - 1
            }

            this.setSelected(newIndex)

            this.sliceScrollTimeout = null
          }, 100, event.deltaY)
        }
      }

      event.preventDefault()
    },
    /**
     * Handles mouse buttons
     * @param {MouseEvent} event - mouse event
     * @memberof KeyMouseHelper
     */
    onMouseDown (e) {
      /**
       * Handling mouse middle button navigation
       */
      if (e.button === 1) {
        if (!this.middleMouseNav) {
          this.middleMouseNav = true
          this.middleMouseNavStart = e.clientY

          let stepSizeY = Math.round(e.clientY / this.images.length)
          this.upperYCheckpoints = reverseFillArray(e.clientY, 10, stepSizeY)
          this.lowerYCheckpoints = fillArray(e.clientY, (this.canvas.parentElement.clientHeight - 10), stepSizeY)

          this.middleMouseStartIndex = this.selectedIndex
  
        }
      }
    },
    onMouseUp () {
      this.middleMouseNav = false
    },
    onMouseOut() {
      this.middleMouseNav = false
    },
    /**
     * Handles mouse movement
     * @param {MouseEvent} event - mouse event
     * @memberof KeyMouseHelper
     */
    onMouseMove (e) {
      /** 
       * Handling mouse movement for mouse middle button navigation
       */
      if (this.middleMouseNav === true) {
        // we want to avoid multiple events for same pixel
        if (this.lastCheckedY === e.clientY) return

        let index = 0
        let newIndex = 0

        if (e.clientY < this.middleMouseNavStart) { 
          index = this.upperYCheckpoints.findIndex(step => step === e.clientY)
          newIndex = this.middleMouseStartIndex + index
        } else {
          index = this.lowerYCheckpoints.findIndex(step => step === e.clientY)
          newIndex = this.middleMouseStartIndex - index
        }

        if (index < 0) return

        this.lastCheckedY = e.clientY

        if (this.changingImageInProgress) return

        if (newIndex >= 0 && newIndex < this.images.length) {
          this.changeImage(newIndex)
        }
      }
    },
    /**
     * Check bounds and increase/decrease value if possible
     * @param {Number} value - current value
     * @param {Number} min - min constraint
     * @param {Number} max - max constraint
     * @param {Number} step - step
     * @param {MouseEvent} event - mouse event to detect wheel direction
     * @returns {Number}
     * @memberof KeyMouseHelper
     */
    handleValueByWheel (value, min, max, step, event) {
      let res = value + (event.deltaY > 0 ? -step : step)

      return res < min 
        ? min
        : res > max
          ? max
          : res
    },
    /**
     * Handles image changing in a safe way to prevent concurrency issues
     * @param {Number} index - index of the image
     * @memberof KeyMouseHelper
     */
    async changeImage(index) {
      this.changingImageInProgress = true
      await this.setSelected(index)
      this.changingImageInProgress = false
    }
  }
}