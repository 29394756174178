<template lang="pug">
  div.menu-item(@click="onClick", :class="classes")
    div.item-icon
      v-icon(v-if="icon" class="mr-2") {{ icon }}
      div.color-select.ml-1(v-if="color", :style="'background-color: ' + color")
    div.item-title
      span(:class="{ 'text-decoration-underline': underLineFirstLetter }") {{ firstLetter }}
      span {{ restOfText }}
    slot
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    icon: String,
    color: {
      type: String,
      default: '',
    },
		title: String,
		disabled: {
			type: Boolean,
			default: false
    },
    ulFirstLetter: Boolean
  },
  data () {
    return {
      underLineFirstLetter: this.ulFirstLetter === undefined ? false : this.ulFirstLetter
    }
  },
  computed: {
    classes () {
      return {
				'disabled': this.disabled
			}
    },
    firstLetter () {
      return this.title.slice(0, 1)
    },
    restOfText () {
      return this.title.slice(1)
    }
  },
  methods: {
    onClick () {
			if (!this.disabled) {
				this.$emit('click')
			}
    }
  }
}
</script>
