<script>
import { mapState, mapMutations } from 'vuex'
import MenuItem from '@/components/Sidebar/MenuItem'

export default {
  name: 'MenuTool',
  extends: MenuItem,
  props: {
    name: String
  },
  computed: {
    ...mapState({
			activeTool: state => state.images.activeTool
    }),
    classes () {
      return {
        'active': this.name === this.activeTool
      }
    }
  },
  methods: {
		...mapMutations({
			setActiveTool: 'images/setActiveTool'
		}),
    onClick () {
			this.setActiveTool(this.name)
    }
  },
  created () {
    this.underLineFirstLetter = true
  }
}
</script>
