import Copier from './Copier.js'

class LinearCopier extends Copier {
  /**
   * Create box tool
   * @constructor
   */
  constructor (annotator, videoDebugMode) {
    super(annotator, videoDebugMode)

    this.useVideo = false
    this.requireEndBox = true
  }

  /*
    numberOfFrames - total number of frames after starting frame, including endframe
  */
  start (boxPoints, endBoxPoints, numberOfFrames) {
    this.topLeftStep = this.getOffset(boxPoints[0][0], boxPoints[0][1], endBoxPoints[0][0], endBoxPoints[0][1], numberOfFrames)
    this.bottomRightStep = this.getOffset(boxPoints[1][0], boxPoints[1][1], endBoxPoints[1][0], endBoxPoints[1][1], numberOfFrames)
  }

  captureFrame () {
    return {
      x1: this.topLeftStep.x,
      y1: this.topLeftStep.y,
      x2: this.bottomRightStep.x,
      y2: this.bottomRightStep.y
    }
  }

  getOffset (x1, y1, x2, y2, frameCount) {
    return {
      x: (x2 - x1) / frameCount,
      y: (y2 - y1) / frameCount
    }
  }
}

export default LinearCopier