<template lang="pug">
  div(class="debug-panel")
    template(v-if="debug")    
      | Media: {{ mediaType }}
      br
      | Mode: {{ mode }}
      br
      | Multilabel: {{ multilabel }}
      br
      | Remote: {{ remote }}  
      br
    | Patient ID: {{ patientID || 'n/a' }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DebugPanel',
  computed: {
    ...mapState({
      mediaType: state => state.images.mediaType,
      mode: state => state.images.mode,
      remote: state => state.images.remote,
      multilabel: state => state.images.multilabel,
      originalName: state => state.images.originalName,
      patientID: state => state.images.patientID
    }),
    debug () {
      return process.env.VUE_APP_DEBUG
    }
  }
}
</script>

<style lang="stylus">
.debug-panel
  position: absolute
  top: 5px
  right: 20px
  border-radius: 5px
  font-size: 10px
  background-color: #4B0F42
  color: white
  z-index: 999
  padding: 5px
</style>
