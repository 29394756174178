<script>
import MenuItem from '@/components/Sidebar/MenuItem'

export default {
  name: 'MenuColor',
  extends: MenuItem,
  props: {
    activeColor: String
  },
  computed: {
    classes () {
      return {
				'active': this.color === this.activeColor
      }
    }
  },
  methods: {
    onClick () {
      this.$emit('click', this.color)
    }
  }
}
</script>
