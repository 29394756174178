import axios from 'axios'
import Vue from 'vue'
import store from '@/store/store.js'
import Router from 'vue-router'
import Annotator from './views/Annotator.vue'
import Login from './views/Login.vue'

Vue.use(Router)

var router = new Router({
  mode: "history",
//  base: "/annotator/",
  base: "/",
  routes: [
    {
      path: '/',
      name: 'Editor',
      component: Annotator
    },
    // Accept redirect from dashboard with datapoint id and token
    {
      path: '/edit/:id/:token/',
      name: 'EditorWithToken',
      component: Annotator
    },
    {
      path: '/edit/:id/',
      name: 'EditorWithID',
      component: Annotator
    },    
    {
      path: "/login",
      name: "login",
      component: Login
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.params.token && to.params.id) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + to.params.token
    store.commit('auth/setLoggedIn', { status: true, name: '' })
    next({ name: 'EditorWithID', params: { id: to.params.id }})
  } else {
    if (store.state.auth.loggedIn || to.path === '/login') {
      next()
    } else {
      next('/login')    
    }  
  }
})

export default router
