<template>
  <v-list dense nav class="py-1">
    <MenuColor v-for="color in colors"
               :key="color.key"
               :color="color.color"
               :title="color.label"
               :activeColor="activeColor"
               @click="$emit('change', color.color)"/>
  </v-list>
</template>

<script>
import MenuColor from '@/components/Sidebar/MenuColor.vue'

export default {
  name: 'SegmentationClassTool',
  components: {
    MenuColor
  },
  props: {
    activeColor: String,
    colors: Array
  }
}
</script>

<style lang="stylus">
</style>
