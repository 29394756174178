<template>
  <div class="loading">
    <v-progress-circular indeterminate 
                         color="#4B0F42" 
                         size="56" 
                         width="5"/>
  </div>  
</template>

<script>
export default {
  name: 'OverlaySpinner'
}
</script>

<style lang="scss">
div.loading {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 500;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
}
</style>