<template>
  <div class="menu-box-selected-info">
    <span class="caption">{{ realTitle }}</span>

    <v-icon
      class="my-2"
      :style="checkStyles">
      {{ value ? 'mdi-check' : 'mdi-close' }}
    </v-icon>

    <div class="d-flex buttons">
      <template v-if="value">
        <v-btn
          x-small
          class="mr-1"
          @click="$emit('show')">
          Show
        </v-btn>

        <v-btn
          x-small
          @click="$emit('reset')">
          Reset
        </v-btn>
      </template>

      <v-btn
        v-else
        x-small
        :disabled="!boxSelected || setDisabled"
        @click="$emit('set')">
        Set
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuBoxLinearCopy',
  props: {
    value: Object, // box
    frame: Number,
    title: String,
    boxSelected: Object,
    setDisabled: Boolean
  },
  computed: {
    checkStyles () {
      return {
        color: this.value ? '#4B0F42' : 'yellow'
      }
    },
    realTitle () {
      return this.title + (this.value
        ? ` (${this.frame + 1})`
        : '')
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-box-selected-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .caption {
    font-size: 14px !important;
  }
}
</style>