/**
 * @module tools/Line
 */
import Tool from './Tool.js'

/**
 * Checks that value is in range (coord - delta) <= value <= (coord + delta)
 * @param {Number} value - value to check
 * @param {Number} coord - base coord
 * @param {Number} delta - coord delta
 * @returns {Boolean} - true if value is in range
 */
function checkRange (value, coord, delta) {
  let coordMinus = Math.floor(coord - delta)
	let coordPlus = Math.ceil(coord + delta)
  return value >= coordMinus && value <= coordPlus
}
/**
 * Line tool
 * @extends Tool
 */
class LineTool extends Tool {
  /**
   * Create line tool
   * @constructor
   * @param {Object} annotator - Annotator vue component reference
   */
  constructor (annotator) {
    super(annotator, 'line')

    // const
    this.lineLineWidth = 2
    // vars
    this.lineMoveStart = []
    this.lineChangeFigure = null,
    this.lineChangeOperation = false
  }  
  /**
   * Handle all mouse events for line
   * @param {Event} e - mouse event 
   */
  handleMouse (e) {
    switch (e.type) {
      case 'mousedown': {
        if (e.button === 0) {
          this.checkBoundingLinePosition()

          if (this.annotator.lineOverFigure) {
            if (this.annotator.lineOverFigurePart === 'inner') {
              this.lineMoveStart = [this.current.x, this.current.y]
              this.lineChangeOperation = 'move'
            } else {
              this.lineChangeOperation = 'resize'
            }

            this.lineChangeFigure = this.annotator.lineOverFigure
          } else {
            this.startFigure(this.annotator.brushColor, this.lineLineWidth)
            // Ensure that we always have two points
            this.current.figure.points.push([
              this.current.figure.points[0][0],
              this.current.figure.points[0][1]
            ])
          }
        }

        break
      }
      case 'mousemove': {
        if (this.current.isDrawing) {
          // Change size of currently drawing figure
          if (this.current.figure.points.length === 1) {
            this.current.figure.points.push([this.current.x, this.current.y])
          } else {
            this.current.figure.points[1][0] = this.current.x
            this.current.figure.points[1][1] = this.current.y 
          }
        } else {        
          if (this.lineChangeOperation) {
            switch (this.lineChangeOperation) {
              case 'move': {
                let dx, dy
                [dx, dy] = [this.current.x - this.lineMoveStart[0], this.current.y - this.lineMoveStart[1]]
                // Check image bounds
                let coordsX = [this.lineChangeFigure.points[0][0] + dx,
                               this.lineChangeFigure.points[1][0] + dx]

                let coordsY = [this.lineChangeFigure.points[0][1] + dy,
                               this.lineChangeFigure.points[1][1] + dy]
                
                if (coordsX.every(c => c >= 0 && c < this.annotator.canvasSize.width) &&
                    coordsY.every(c => c >= 0 && c < this.annotator.canvasSize.height)) {
                  this.lineChangeFigure.points[0][0] += dx
                  this.lineChangeFigure.points[1][0] += dx
                  this.lineChangeFigure.points[0][1] += dy
                  this.lineChangeFigure.points[1][1] += dy
                }

                // update start position
                this.lineMoveStart = [this.current.x, this.current.y]
                this.annotator.updateRedrawAll = true
                break
              }
              case 'resize': {
                switch (this.annotator.lineOverFigurePart) {
                  case 'start': {
                    this.lineChangeFigure.points[0][0] = this.current.x
                    this.lineChangeFigure.points[0][1] = this.current.y
                    break
                  }
                  case 'end': {
                    this.lineChangeFigure.points[1][0] = this.current.x
                    this.lineChangeFigure.points[1][1] = this.current.y
                  }
                }

                this.annotator.updateRedrawAll = true
                break
              }
            }
          } else {
            let oldLineOverFigure = this.annotator.lineOverFigure
            this.checkBoundingLinePosition()
            // Redraw all only if active figure changed
            this.annotator.updateRedrawAll = oldLineOverFigure !== this.annotator.lineOverFigure
          }									
        }

        break
      }
      case 'mouseout': 
      case 'mouseup': {
        this.checkBoundingLinePosition()

        if (this.current.isDrawing) {
          this.current.isDrawing = false
          this.finishDrawing()
        } else {
          if (e.type === 'mouseup') {
            this.checkBoundingLinePosition()
          } else {
            this.annotator.lineOverFigure = null
          }

          this.lineChangeFigure = null
          this.lineChangeOperation = false
        }

        break
      }
      case 'dblclick': {
        this.checkBoundingLinePosition()
        this.annotator.lineSelected = this.annotator.lineSelected === this.annotator.lineOverFigure
          ? null
          : this.annotator.lineOverFigure

        this.annotator.updateRedrawAll = true
        break
      }
    }
  }
  /**
   * Draw given line figure on given canvas
   * @param {CanvasRenderingContext2D} canvasCtx - target canvas context
   * @param {Object} figure - figure to draw
   * @param {Number} alpha - alpha level to use for brush (eraser always use 1)
   */
  drawFigure (canvasCtx, figure) {
    canvasCtx.strokeStyle = figure.color
    canvasCtx.fillStyle = figure.color
    canvasCtx.lineWidth = figure.lineWidth
    canvasCtx.globalAlpha = 1

    let x1, y1, x2, y2
    [x1, y1, x2, y2] = this.normalizeLineCoords(figure)

    // Line
    canvasCtx.beginPath()
    canvasCtx.moveTo(x1, y1)
    canvasCtx.lineTo(x2, y2)
    canvasCtx.stroke()

    // Highlight figure on hover
    canvasCtx.globalAlpha = figure === this.annotator.lineSelected
      ? 1
      : figure === this.annotator.lineOverFigure
        ? 0.9
        : 0.7

    canvasCtx.fill()

    // 2-points
    canvasCtx.globalAlpha = 1            
    canvasCtx.beginPath()
    canvasCtx.arc(x1, y1, figure.lineWidth * 2, 0, Math.PI * 2)
    canvasCtx.fill()

    canvasCtx.beginPath()
    canvasCtx.arc(x2, y2, figure.lineWidth * 2, 0, Math.PI * 2)
    canvasCtx.fill()

  }
  /**
   * Ensure that first point is top-left and second bottom-right
   * @param {Object} figure - figure object to normalize
   * @returns {Array.<Number>} - array of coordinates: x1, y1, x2, y2
   */
  normalizeLineCoords (figure) {
    let x1 = figure.points[0][0]
    let y1 = figure.points[0][1]
    
    if (figure.points.length > 1) {
      let x2 = figure.points[1][0]
      let y2 = figure.points[1][1]
      return [x1, y1, x2, y2]
    } else {
      return [x1, y1, x1, y1]
    }
  }
  checkIfCursorOverLine (x1, x2, y1, y2) {
    var dx = x2 - x1;
    var dy = y2 - y1;
    var d = Math.abs(dy*this.current.x - dx*this.current.y - x1*y2+x2*y1)/Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

    // sort coordinates

    if (x1 > x2) {
      [x1, x2] = [x2, x1]
    }

    if (y1 > y2) {
      [y1, y2] = [y2, y1]
    }

    if (this.current.x >= x1 && this.current.x <= x2 && this.current.y >= y1 && this.current.y <= y2) {
      if (d <= this.lineLineWidth) {
        return true
      }
    }
    return false
  
  }
  /**
   * Check if cursor is over one of the linees and where exactly
   */
  checkBoundingLinePosition () {
    if (this.current.x) {
      // checking from last to first
      let resFigure = null

      for (let i = this.annotator.selectedAnnotations.length-1; i >= 0; i--) {
        let figure = this.annotator.selectedAnnotations[i]

        // Get coordinates and swap them if needed
        let x1, y1, x2, y2
        [x1, y1, x2, y2] = this.normalizeLineCoords(figure)

        let halfCircle = this.lineLineWidth

        // Check
        let cursorOverLine = this.checkIfCursorOverLine(x1, x2, y1, y2)

        if (cursorOverLine) {
          // Detect exact position
          if (checkRange(this.current.x, x1, halfCircle) && checkRange(this.current.y, y1, halfCircle)) {
            this.annotator.lineOverFigurePart = 'start'
            console.log("START")
          } else if (checkRange(this.current.x, x2, halfCircle) && checkRange(this.current.y, y2, halfCircle)) {
            this.annotator.lineOverFigurePart = 'end'
            console.log("END")
          } else {
            this.annotator.lineOverFigurePart = 'inner'
            console.log("INNER")
          }

          // Stop furher checks
          resFigure = figure
          break
        }
      }

      this.annotator.lineOverFigure = resFigure

    } else {
      this.annotator.lineOverFigure = null
    }
  }
}

export default LineTool