// We use @namespace istead of @module due to jsdoc limitations on processing functions within objects
/**
 * All image manipulation state & actions
 * @namespace store/images
 */
import Vue from 'vue'
import axios from 'axios'
import Zip from 'jszip'
import { isGraphicFile, isVideoFile, getFileExtension } from '@/util/FileUtil.js'
import DICOMConverter from '@/util/DICOMConverter.js'
import BLOBToImage from '@/util/BLOBToImage.js'
import { apiError } from '@/util/ErrorMessage'
import { isNormalInteger } from '@/util/Util.js'

// mpn 65
const defaultColors = [
	'ff0029', '377eb8', '66a61e', '984ea3', '00d2d5', 'ff7f00', 'af8d00',
	'7f80cd', 'b3e900', 'c42e60', 'a65628', 'f781bf', '8dd3c7', 'bebada',
	'fb8072', '80b1d3', 'fdb462', 'fccde5', 'bc80bd', 'ffed6f', 'c4eaff',
	'cf8c00', '1b9e77', 'd95f02', 'e7298a', 'e6ab02', 'a6761d', '0097ff',
	'00d067', '000000', '252525', '525252', '737373', '969696', 'bdbdbd',
	'f43600', '4ba93b', '5779bb', '927acc', '97ee3f', 'bf3947', '9f5b00',
	'f48758', '8caed6', 'f2b94f', 'eff26e', 'e43872', 'd9b100', '9d7a00',
	'698cff', 'd9d9d9', '00d27e', 'd06800', '009f82', 'c49200', 'cbe8ff',
	'fecddf', 'c27eb6', '8cd2ce', 'c4b8d9', 'f883b0', 'a49100', 'f48800',
	'27d0df', 'a04a9b'
]

export default {
  namespaced: true,
  state: {
    currentURL: '',
    prevURL: '',
    nextURL: '',    
    // File properties
    // Media type: image | video
    mediaType: '',
    // Mode type:
    // For mediaType === 'image': segmentation | form | box
    // For mediaType === 'video': box
    mode: '',
    remote: false,
    datapointURL: '',
    originalName: '',
    multilabel: true,
    patientID: '',
    annotationToEdit: null,
    predictions: [],
    /* Options for segmentation mode
      name: String,
      colors: Object
    */
    segmentation: {
      name: '',
      colors: []
    },
    // Form fields for form mode
    form: {
			name: '',
			form: []
		},
		boundingBox: {
			name: '',
			colors: []
		},
    line: {
      name: '',
      colors: []
    },
    /* Images (one for png/dicom and multiple for zip)
       Consists of objects:
       {
         loaded: Boolean 			- file fully loaded & decoded if needed
				 image: Image     		- loaded and decoded image        
				 imageData: ImageData - image data for magicwand 
         name: String     	  - file name
				 annotations: []  		- array of figure objects
				 mask: Image					- decoded and loaded mask if any
       } 
    */
    images: [],
    
		// Tools
		activeTool: 'pointer',
		brushColor: '#ff0000',
		// selected image
    //selectedIndex: false,
    selectedIndex: 0,
    selectedImage: null, // -> images[]

    // Video information for mediaType === 'video' mode
    video: {
      interval: 0.1,      
      duration: 0,
      width: 0,
      height: 0,
      frames: 0,
      src: ''
    },
    // window level min max for all frames processed
    windowLevel: {
      present: false,
      min: 0,
      max: 0,
      a: 0,
      b: 0
    },

    shiftStatus: false
  },
  mutations: {
    setCurrentURL (state, currentURL) {
      state.currentURL = currentURL
    },
    setNextURL (state, nextURL) {
      state.nextURL = nextURL
    },
    setPrevURL (state, prevURL) {
      state.prevURL = prevURL
    },  
    /**
     * [Mutation] Sets initial file info
     * @param {Object} state - store state object
     * @param {Object} param1 - file into object
     * @memberof store/images
     */
    setFileinfo (state, { mediaType, mode, remote, originalName, url, multilabel, patientID }) {
      state.mediaType = mediaType
      state.mode = mode
      state.remote = remote
      state.originalName = originalName
      state.datapointURL = url
      state.multilabel = multilabel
      state.patientID = patientID
    },
    /**
     * [Mutation] Sets specific info for current mode
		 * Also selects default tool
     * @param {Object} state 
     * @param {Object} modeInfo 
     * @memberof store/images
     */
    setModeOptions (state, modeInfo) {
      switch (state.mode) {
        case 'segmentation': {
          state.segmentation = modeInfo
          state.brushColor = modeInfo.colors[0].color
          break
        }
        case 'form': {
          state.form = modeInfo
          break
				}
				case 'box': {
					state.boundingBox = modeInfo
					state.brushColor = modeInfo.colors[0].color
					break
				}
        case 'line': {
          state.line = modeInfo
					state.brushColor = modeInfo.colors[0].color
        }
			}
			
			state.activeTool = state.mode === 'segmentation'
				? 'brush' 
				: state.mode === 'box' 
					? 'box'
					: state.mode === 'line'
            ? 'line'
            : 'pointer'
    },
    /**
     * [Mutation] Initialize store for loading  images
     * @param {Object} state 
     * @param {Number} count - number of images to initialize the array
     * @memberof store/images
     */
    setInitImages (state, count) {
     state.images = Array(count).fill({
       annotations: [],
       loaded: false
     })
    },
    /**
     * [Mutation] Set image data (unzipped, decoded and so on) and loaded flag
     * @param {Object} state 
     * @param {Number} index - image index
     * @param {Blob} image - image data
     * @param {String} name - image name
     * @memberof store/images
     */
    setImageLoaded (state, { index, image, name, dicom, wl }) {
      let data = {
        image: image.image,
        imageData: image.imageData,
        name,
        annotations: [],
        loaded: true,
        dicom,
      }

      // Add current values on image load
      if (wl) {
        data.wl = {
          min: wl.min,
          max: wl.max,
          a: wl.min,
          b: wl.max
        }

        // update global min/max
        if (wl.min < state.windowLevel.min) {
          state.windowLevel.min = wl.min
        }
  
        if (wl.max > state.windowLevel.max) {
          state.windowLevel.max = wl.max
        }
  
        if (!state.windowLevel.present) {
          state.windowLevel.a = wl.min
          state.windowLevel.b = wl.max
          state.windowLevel.present = true  
        }        
      }

      Vue.set(state.images, index, data)

      if (index === 0) {
				state.selectedIndex = 0
        state.selectedImage = state.images[0]	
      }
		},
    updateImageWindowLevel (state, { index, a, b }) {
      state.windowLevel.a = a
      state.windowLevel.b = b
      // also keep in image
      state.images[index].wl.a = a
      state.images[index].wl.b = b
    },
    updateImage (state, { index, image }) {
      state.images[index].image = image.image
    },
		/**
		 * [Mutation] Set predefined image mask when it is loaded from file
		 * @param {Object} state 
		 * @param {Number} index - image index
		 * @param {Blob} mask - mask image data
     * @memberof store/images
		 */
		setFileMask (state, { index, mask }) {
			state.images[index].mask = mask
		},
		setSelected (state, index) {
      if (state.selectedIndex === index) {
        return
      }
			state.selectedIndex = index
			state.selectedImage = state.images[index]
		},		
    setBrushColor (state, color) {
      state.brushColor = color
		},
		setActiveTool (state, tool) {
			state.activeTool = tool
    },
    /**
     * [Mutation] Reset all state in case of error while loading
     */
    reset (state) {
      state.mode = ''
      state.remote = false
      state.datapointURL = ''
      state.originalName = ''
      state.multilabel = true
      state.patientID = ''
      state.images = []
      state.selectedIndex = false
      state.selectedImage = null
      state.annotationToEdit = null	
    },
    setVideoInfo (state, { duration, interval, width, height, src }) {
      state.video.duration = duration
      state.video.interval = interval
      state.video.width = width
      state.video.height = height
      state.video.frames = Math.ceil(duration / interval)
      state.video.src = src
    },
    setAnnotationToEdit (state, { annotation }) {
      state.annotationToEdit = annotation
    },
    setAnnotation (state, { index, data }) {
      state.images[index].annotations = data
    },
    resetWindowLevel (state) {
      state.windowLevel = {
        present: false,
        min: 0,
        max: 0,
        a: 0,
        b: 0
      }
    },

    setShiftStatus (state, value) {
      state.shiftStatus = value
    }
  },
  actions: {
    /**
     * [Action] If needed, decodes DICOM, converts Blob to Image and sets store data
     * @param {Object} context - store context
     * @param {Blob} file - image blob object
     * @memberof store/images
     */
    async addOneFile ({ commit }, { index, file }) {
      if (isGraphicFile(file.name)) {
        // generic files 
        let image = await BLOBToImage(file)
        commit('setImageLoaded', { index, image, name: file.name })
      } else if (isVideoFile(file.name)) {
        // !!!

      } else {
        // dicoms
        let blobs = await DICOMConverter(file)

        // Do not use forEach since it does not await async callback
        if (blobs.length == 1) {
          commit('setImageLoaded', {
            index,
            image: await BLOBToImage(blobs[0].blob),
            name: file.name,
            dicom: file,
            wl: blobs[0].wl
          })
        } else {
          await Promise.all(blobs.map(async (blob, index) => {
            commit('setImageLoaded', {
              index,
              image: await BLOBToImage(blob.blob),
              name: blobs.length > 1 
                ? `${index.toString().padStart(3, "0")}-${file.name}`
                : file.name,
              // In this mode it is still not working correctly
              //dicom: blob,
              //wl: blob.wl
            })
          }))
        }
      }
		},
    async updateFileWindowLevel ({ state, commit }, index) {
      const windowLevel = {
        center: state.windowLevel.a + ((state.windowLevel.b - state.windowLevel.a) / 2),
        width: state.windowLevel.b - state.windowLevel.a
      }

      const currentImage = state.images[index]
      const blobs = await DICOMConverter(currentImage.dicom, 0, windowLevel)

      commit('updateImage', {
        index,
        image: await BLOBToImage(blobs[0].blob)
      })
    },
		/**
		 * [Action] Loads mask for one image from disk
     * @memberof store/images
		 */
		async loadMaskFromDisk ({ commit }, { index, file }) {
			try {
				if (isGraphicFile(file.name)) {
					let mask = await BLOBToImage(file)
					commit('setFileMask', { index, mask: mask.image })
				}
			} catch (error) {
				apiError(error)
			}
		},
    /**
     * [Action] Load local file from disk
     * @param {Object} { commit } - store context
     * @param {FileList} files - FileList object
     * @memberof store/images
     */
    async loadFromDisk ({ commit, dispatch }, files) {
      commit('setBusy', true, { root: true })

      try {
        // Default mode for local files is image:segmentation
        commit('setFileinfo', {
          mediaType: 'image',
          mode: 'segmentation',
          remote: false,
          originalName: files.length === 1 ? files[0].name : 'Multiple files',
          multilabel: true,
          patientID: false
        })
        // Default color set for local files
        let opt = {
          name: 'Default colors',
          colors: []
        }        

        for (let i = 0; i < 6; i++) {
          opt.colors.push({
            label: `Color ${i}`,
            color: '#' + defaultColors[i]
          })
        }

        commit('setModeOptions', opt)
        // Initialize images array
        commit('setInitImages', files.length)

        await Promise.all(Array.from(files).map((file, index) => {
          return dispatch('addOneFile', { index, file })  
        }))

        commit('setNextURL', false)
        commit('setPrevURL', false)
      } catch (error) {
        apiError(error)
      }

      commit('setBusy', false, { root: true })
    },
    /**
     * [Action] Loads information about image from server
     * @param {Boolean} backwards - use prevURL from previous request
     * @memberof store/images
     */
    async preloadFromServer ({ state, commit }, mode = '') {
      const defURL = '/datapoints/to_annotate/'

      commit('resetWindowLevel')

      let url

      if (mode) {
        switch (mode) {
          case 'next': {
            url = state.nextURL || defURL
            break
          }
          case 'back': {
            url = state.prevURL || defURL
            break
          }
          case 'current': {
            url = state.currentURL || defURL
            break
          }
          default: {
            url = `/annotations/${mode}/redo/`
            break
          }
        }  
      } else {
        url = defURL
      }

      // Remember current URL
      commit('setCurrentURL', url)

      let response = await axios.get(url)

      if (response.data.count > 0) {
        let file = response.data.results[0]

        // Detect media type
        const mediaType = isVideoFile(file.datapoint.file)
          ? 'video'
          : 'image'

        commit('setFileinfo', {
          mediaType,
          mode: file.task.scheme.type, 
          remote: true,
          originalName: file.datapoint.file,
          url: file.datapoint.url,
          multilabel: !('multilabel' in file.task.scheme) || file.task.scheme.multilabel,
          patientID: file.datapoint.patientid
        })   
      }

      return response
    },
    /**
     * [Action] Process loaded image(s) and add them to store
     * @memberof store/images
     */
    async postloadFromServer ({ state, commit, dispatch }, { file, binary, mode, downloadFile }) {
      if (getFileExtension(file.datapoint.file) === 'zip') {
        // Parse zip archive
        let arc = await Zip.loadAsync(binary)
        let files = Object.values(arc.files).filter((file) => (!file.dir && !file.name.includes('DS_Store')))

        // Apply debug files limit if any
        // if (process.env.VUE_APP_MAX_FILES) {
        //   files = files.slice(0, process.env.VUE_APP_MAX_FILES)
        // }
        // After filtering they may not be in the right order so sort them  
        files.sort((a, b) => (a.name.localeCompare(b.name)))

        // Init images array by number of files
        commit('setInitImages', files.length)

        // Unzip files in parallel mode
        // eslint-disable-next-line
        let fileTasks = files.map((file, index) => new Promise(async (resolve) => {
          try {
            let blob = await arc.file(file.name).async("blob")
            let path = file.name.split('/')
            blob.name = (path.length === 1) ? path[0] : path[path.length-1]

            await dispatch('addOneFile', { index, file: blob })
          } catch (error) {
            apiError(error)
          }

          resolve()          
        }))

        await Promise.all(fileTasks)
      } else {
        // single images or dicom (single or multiframe)
        let blob = new Blob([new Uint8Array(binary)])

        let path = state.originalName.split('/')
        blob.name = (path.length === 1) ? path[0] : path[path.length-1]
        
        if (state.mediaType === 'video') {
          // Special handling for videos
          // We need to detect full video length
          let video = document.createElement('video')
          video.preload = 'metadata'

          // For S3 we need to get local URL instead of s3:// because s3 can not be assigned to src prop
          if (file.datapoint.file.startsWith('s3://')) {
            const blob = new Blob([new Uint8Array(binary)])
            file.datapoint.file = URL.createObjectURL(blob)
          }          

          await new Promise(resolve => {
            video.addEventListener('loadedmetadata', () => {
              resolve()
            }) 
          
            video.src = file.datapoint.file
          })
          
          let interval = 0.1
          // Load predictions in video box mode
          let prediction = file.datapoint && file.datapoint.predictions && file.datapoint.predictions.length > 0
          
          if (prediction) {
            let annotationToEdit = await axios.get(file.datapoint.predictions[0])

            if (annotationToEdit.data.file) {
              // Download file uses clear axios for Google but returns binary data
              let prediction_file = await downloadFile(annotationToEdit.data.file)
              // So we need to convert response into JSON
              prediction = JSON.parse(new TextDecoder().decode(prediction_file.data))

              // Adjust interval and frames
              if (prediction.images.length !== state.images.length) {
                interval = video.duration / prediction.images.length
              }

              commit('setAnnotationToEdit', {
                  annotation: annotationToEdit.data,
              })
            } else {
              prediction = false
            }
          }          

          // Probably in future we should receive interval from server
          commit('setVideoInfo', {
            duration: video.duration,
            width: video.videoWidth,
            height: video.videoHeight,
            interval,
            src: file.datapoint.file
          })         

          // Instead of add one file create an object for each frame
          commit('setInitImages', state.video.frames)

          for (let index = 0; index < state.video.frames; index++) {
            commit('setImageLoaded', {
              index,
              image: blob,
              name: file.name
            })
          }

          if (prediction) {
            prediction.images.forEach((pred, index) => {
              commit('setAnnotation', {
                index,
                data: pred.map(p => ({
                  color: p.color,
                  // We probably should save lineWidth too
                  lineWidth: 2,
                  tool: 'box',
                  points: [
                    [p.x1, p.y1],
                    [p.x2, p.y2]
                  ]
                }))                
              })
            })
          }
        } else {
          // Images
          commit('setInitImages', 1)                
 
          await dispatch('addOneFile', { index: 0, file: blob })  
        }
      }
          
      let modeOptions = {
        name: file.task.scheme.name
      }

      switch (state.mode) {
        case 'segmentation': {
          // Colors for segmentation
          // Array mode
          if (Array.isArray(file.task.scheme.description)) {
            modeOptions.colors = file.task.scheme.description.map((color, index) => ({
              label: color,
              color: '#' + defaultColors[index]        
            })) 
          } else {
            // Object mode
            modeOptions.colors = Object
              .keys(file.task.scheme.description)
              .reduce((acc, key) => {
                acc.push({
                  label: key,
                  color: file.task.scheme.description[key]
                })
                return acc
              }, [])
          }

          // Masks preloading
          if (file.datapoint.predictions && file.datapoint.predictions.length > 0) {
            let requests = file.datapoint.predictions.map(url => axios.get(url))

            let responses = await Promise.all(requests)

            if (isNormalInteger(mode)) {
              responses = responses.filter(f => f.data.id === Number(mode))
            }

            responses = responses
              .map(r => r.data.file)
              .filter(f => f)

            let responseOut = []

            for (let i = 0; i < responses.length; i++) {
              if (getFileExtension(responses[i]) === 'zip') {                
                // Download using callback
                let res = await downloadFile(responses[i])
                // Parse ZIP archive
                let arc = await Zip.loadAsync(res.data)
                //
                let files = Object.values(arc.files).filter((file) => (!file.dir && !file.name.includes('DS_Store')))

                // After filtering they may not be in the right order so sort them  
                files.sort((a, b) => (a.name.localeCompare(b.name)))

                for (let k = 0; k < files.length; k++) {
                  let blob = await arc.file(files[k].name).async("blob")

                  responseOut.push({
                    name: files[k].name,
                    url: URL.createObjectURL(blob)
                  })
                }
              } else {
                responseOut.push({
                  name: responses[i],
                  url: responses[i]
                })
              }
            }

            let numMasks = Math.min(state.images.length, responseOut.length)

            for (let i = 0; i < numMasks; i++) {
              await new Promise((resolve) => {
                let image = new Image()

                image.setAttribute('crossorigin', 'anonymous')

                image.onload = () => {
                  commit('setFileMask', { index: i, mask: image })
                  resolve(true)
                }
            
                image.src = responseOut[i].url
              })
            }
          }
          //---

          /*  
          // Assigning keys to colors
          let availableKeys = ['q', 'w', 'e', 'r', 't', 'a']

          modeOptions.colors.forEach(color => {
            if (availableKeys.length > 0) {
              color.key = availableKeys[0]
              availableKeys.splice(0, 1)
            }
          })
          */
          
          break
        }
        case 'form': {
          // Form data for form, add empty result field
          if (Array.isArray(file.task.scheme.description)) {
            modeOptions.form = file.task.scheme.description.map(item => {
              let res = item
              res.result = ''
              return res
            })	
          } else {
            // otherwise handle it as object
            modeOptions.form = [file.task.scheme.description]
            modeOptions.form[0].result = ''
          }

          break
        }
        case 'box': {
          // Default colors for bounding boxes
          modeOptions.colors = []

          for (let i = 0; i < file.task.scheme.description.length; i++) {
            modeOptions.colors.push({
              label: file.task.scheme.description[i],
              color: '#' + defaultColors[i]	
            })
          }          

          break
        }
        case 'line': {
          // Default colors for line tool
          modeOptions.colors = []

          for (let i = 0; i < file.task.scheme.description.length; i++) {
            modeOptions.colors.push({
              label: file.task.scheme.description[i],
              color: '#' + defaultColors[i]
            })
          }

          break
        }
      }

      commit('setModeOptions', modeOptions)
    },
		async setSelected ({ state, commit, dispatch }, index) {
      if (state.selectedIndex == index) {
        return
      }

      const newImage = state.images[index]

      if (state.windowLevel.present && newImage.wl) {
        // update 2d image with new values
        await dispatch('updateFileWindowLevel', index)
      }

      // change 
      commit('setSelected', index)
		}
  }
}
