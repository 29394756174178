<template lang="pug">
  div.buttons-container(class="d-flex flex-row justify-space-between my-1")
    div.button-prev-next.left(@click="onClickPrev", :class="{ 'disabled': !prevURL}")
      v-icon(class="mr-2") mdi-arrow-left-bold-circle
      | Prev
    div.button-prev-next.right(@click="onClickNext", :class="{ 'disabled': !nextURL}")
      | Next
      v-icon(class="ml-2") mdi-arrow-right-bold-circle
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuPrevNext',
  props: {
    icon: String,
    color: {
      type: String,
      default: '',
    },
    title: String
  },
  computed: {
    ...mapState({
      nextURL: state => state.images.nextURL,
      prevURL: state => state.images.prevURL
    }),
    classes () {
      return {
				'disabled': this.disabled
			}
    }
  },
  methods: {
    onClickPrev () {
			if (this.prevURL) {
        this.$emit('prev')
			}
    },
    onClickNext () {
			if (this.nextURL) {
        this.$emit('next')
			}
    }
  }
}
</script>

<style lang="stylus">
@import "~@/assets/vars.styl"

.buttons-container 

  .button-prev-next
    padding: 5px

    &.left
      margin-left: -5px

    &.right
      margin-right: -5px

    &:hover
      border-radius: 3px !important
      background-color: #333
      cursor: pointer

    &.disabled
      color: disabledColor

      i
        color: disabledColor

      &:hover
        cursor: default
        background-color: transparent

</style>