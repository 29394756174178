import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#FF006E',
        accent: '#4B0F42'  
      }  
    }
  },
  icons: {
    iconfont: 'mdi',
  }
})

