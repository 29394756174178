/**
 * Converts DICOM file into array of BLOB objects, handles both single frame DICOMs & multiframe DICOMs
 * @module util/DICOMConverter
 * 
 * @param {File} file - incoming DICOM File object
 * @returns {Array.<Blob>} - array of image BLOB objects
 */
import dwv from 'dwv'

let dwvRenderID = 1

function processOneFrame (res, index, viewController, canvas, file) {
  res.push(new Promise((resolve) => {
    viewController.setCurrentFrame(index)

    const windowLevel = viewController.getWindowLevelMinMax()

    canvas.toBlob((blob) => {           
      // pass filename
      blob.name = file.name

      const center = windowLevel.getCenter()
      const width = windowLevel.getWidth()

      resolve({
        blob,
        wl: {
          min: center - (width / 2),
          max: center + (width / 2)
        }
      })
    })    
  })) 
}

export default function (file, index = -1, windowLevel = null) {
  return new Promise(resolve => {
    /* 
      create DOM elements with dwv structure for rendering
      <div id="dwvRoot_xxx">
        <div id="dwv_xxx">
          <div class="layerContainer">
            <canvas class="imageLayer" id="imageCanvas"></canvas>
          </div>
        </div>      
      </div>
    */

    let rootDIV = document.createElement('div')
    rootDIV.id = 'dwvRoot_' + dwvRenderID
    // dwv does not work with 'display: none' style so we hide this divs using 'opacity: 0'
    // also we need this rootDIV because hidden dwv div does not work
    rootDIV.style.cssText = 'position: absolute; top: 0; left: 0; opacity: 0'        
    
    document.body.appendChild(rootDIV)

    let dwvDIV = document.createElement('div')
    dwvDIV.id = 'dwv_' + dwvRenderID
    rootDIV.appendChild(dwvDIV)

    let containerDIV = document.createElement('div')
    containerDIV.className = 'layerContainer'

    dwvDIV.appendChild(containerDIV)

    let canvas = document.createElement('canvas')
    canvas.id = 'imageCanvas'
    canvas.className = 'imageLayer'

    containerDIV.appendChild(canvas)

    // create dwv instance
    let dwvApp = new dwv.App()
    dwvApp.init({ "containerDivId": "dwv_" + dwvRenderID })  
    // dwv init call sets windows.onresize handler and does not remove it, this causes 
    // errors in console on window resize so we reset this handler
    window.onresize = null

    dwvRenderID++

    dwvApp.addEventListener("load-end", function () {
      dwvApp.removeEventListener("load-end", this)

      let image = dwvApp.getImage()
      let viewController = dwvApp.getViewController()
     
      let res = []
      
      if (index >= 0) {
        viewController.setWindowLevel(windowLevel.center, windowLevel.width)
        processOneFrame(res, 0, viewController, canvas, file)
      } else {
        for (let i = 0; i < image.getNumberOfFrames(); i++) {
          processOneFrame(res, i, viewController, canvas, file)
        }  
      }

      Promise.all(res).then(values => {
        document.body.removeChild(rootDIV)
        resolve(values)
      })
    })

    dwvApp.loadFiles([file])
  })
}