/**
 * @module tools/Poly
 */
import Tool from './Tool.js'
/**
 * Poly tool
 * @extends Tool
 */
class PolyTool extends Tool {
  /**
   * Create poly tool
   * @constructor
   * @param {Object} annotator - Annotator vue component reference
   */
  constructor (annotator) {
    super(annotator, 'poly', 'p')
    this.usesRMB = true 
  }  
  /**
   * Handle all mouse events for poly
   * @param {Event} e - mouse event 
   */
  handleMouse (e) {
    switch (e.type) {
      case 'mousedown': {
        // Left button only								
        if (e.button === 0) {
          this.current.mouseButtonHold = true

          if (this.current.isDrawing) {
            this.addPoint(this.current.x, this.current.y)
          } else {
            this.startFigure(this.annotator.brushColor, 2)
            this.current.figure.finished = false
          }
        }

        break
      }
      case 'mouseup': {
        // Left button only
        if (e.button === 0) {
          this.current.mouseButtonHold = false
        }

        break
      }
      // Allow free-hand draw if mouse button pressed & hold
      case 'mousemove': {
        if (this.current.isDrawing) {
          if (this.current.mouseButtonHold) {
            this.addPoint(this.current.x, this.current.y)
          } else {
            if (this.current.figure.points.length > 2 && this.pointsClose(this.current.figure.points[0], this.current)) {
              this.polyContextMenu()
            }
          }  
        }

        break
      }
      case 'mouseout': {
        this.current.mouseButtonHold = false
        break
      }
      case 'contextmenu': {
        this.polyContextMenu()
        e.preventDefault()
        break
      }
    }
  }

  polyContextMenu () {
    this.current.isDrawing = false
    this.current.figure.finished = true
    this.finishDrawing()
  }

  pointsClose (base, test) {
    const delta = 3

    const baseMinX = base[0] - delta
    const baseMaxX = base[0] + delta
    const baseMinY = base[1] - delta
    const baseMaxY = base[1] + delta

    return (test.x > baseMinX && test.x < baseMaxX && test.y > baseMinY && test.y < baseMaxY)
  }

  /**
   * Draw given poly figure on given canvas
   * @param {CanvasRenderingContext2D} canvasCtx - target canvas context
   * @param {Object} figure - figure to draw
   * @param {Number} alpha - alpha level to use for brush (eraser always use 1)
   */
  drawFigure (canvasCtx, figure, alpha) {
    // draw only if points number is more than 1
    if (figure.points.length > 1) {
      canvasCtx.lineWidth = figure.lineWidth
      canvasCtx.strokeStyle = figure.color
      canvasCtx.globalAlpha = alpha
      canvasCtx.beginPath()        
      canvasCtx.moveTo(figure.points[0][0], figure.points[0][1])

      for (let i = 0; i < figure.points.length-1; i++) {
        canvasCtx.lineTo(figure.points[i+1][0], figure.points[i+1][1])
      }

      // if we have at least 3 points and figure is finished
      if (figure.finished && figure.points.length >= 3) {
        canvasCtx.lineTo(figure.points[0][0], figure.points[0][1])

        canvasCtx.stroke()

        // fill 
        canvasCtx.fillStyle = figure.color
        canvasCtx.fill()
      } else {
        canvasCtx.stroke()
      }
    }
  }
}

export default PolyTool
