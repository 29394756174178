class Copier {
  /**
   * Create copier
   * @constructor
   * @param {Object} annotator - Annotator vue component reference
   */
  constructor (annotator, videoDebugMode) {
     this.annotator = annotator
     this.videoDebugMode = videoDebugMode
  }
}

export default Copier