/**
 * @module tools/Box
 */
import Tool from './Tool.js'
import store from '@/store/store'

/**
 * Checks that value is in range (coord - delta) <= value <= (coord + delta)
 * @param {Number} value - value to check
 * @param {Number} coord - base coord
 * @param {Number} delta - coord delta
 * @returns {Boolean} - true if value is in range
 */
function checkRange (value, coord, delta) {
  let coordMinus = Math.floor(coord - delta)
	let coordPlus = Math.ceil(coord + delta)
  return value >= coordMinus && value <= coordPlus
}
/**
 * Box tool
 * @extends Tool
 */
class BoxTool extends Tool {
  /**
   * Create box tool
   * @constructor
   * @param {Object} annotator - Annotator vue component reference
   */
  constructor (annotator) {
    super(annotator, 'box')

    // const
    this.boxLineWidth = 2
    // vars
    this.boxMoveStart = []
    this.boxChangeFigure = null,
    this.boxChangeOperation = false
  }  
  /**
   * Handle all mouse events for box
   * @param {Event} e - mouse event 
   */
  handleMouse (e) {
    switch (e.type) {
      case 'mousedown': {
        if (e.button === 0) {
          this.checkBoundingBoxPosition()

          if (this.annotator.boxOverFigure) {
            if (this.annotator.boxOverFigurePart === 'inner') {
              this.boxMoveStart = [this.current.x, this.current.y]
              this.boxChangeOperation = 'move'
            } else {
              this.boxChangeOperation = 'resize'
            }

            this.boxChangeFigure = this.annotator.boxOverFigure
          } else {
            this.startFigure(this.annotator.brushColor, this.boxLineWidth)
            // Ensure that we always have two points
            this.current.figure.points.push([
              this.current.figure.points[0][0],
              this.current.figure.points[0][1]
            ])
          }
        }

        break
      }
      case 'mousemove': {
        if (this.current.isDrawing) {
          // Change size of currently drawing figure
          if (this.current.figure.points.length === 1) {
            this.current.figure.points.push([this.current.x, this.current.y])
          } else {
            if (store.state.images.shiftStatus) {
              const sideSize = Math.min(Math.abs(this.current.x - this.current.figure.points[0][0]), Math.abs(this.current.y - this.current.figure.points[0][1]))

              this.current.figure.points[1][0] = this.current.figure.points[0][0] + sideSize
              this.current.figure.points[1][1] = this.current.figure.points[0][1] + sideSize
            } else {
              this.current.figure.points[1][0] = this.current.x
              this.current.figure.points[1][1] = this.current.y  
            }

            this.annotator.updateRedrawAll = true
          }
        } else {        
          if (this.boxChangeOperation) {
            switch (this.boxChangeOperation) {
              case 'move': {
                let dx, dy
                [dx, dy] = [this.current.x - this.boxMoveStart[0], this.current.y - this.boxMoveStart[1]]
                // Check image bounds
                let coordsX = [this.boxChangeFigure.points[0][0] + dx,
                               this.boxChangeFigure.points[1][0] + dx]

                let coordsY = [this.boxChangeFigure.points[0][1] + dy,
                               this.boxChangeFigure.points[1][1] + dy]
                
                if (coordsX.every(c => c >= 0 && c < this.annotator.canvasSize.width) &&
                    coordsY.every(c => c >= 0 && c < this.annotator.canvasSize.height)) {
                  this.boxChangeFigure.points[0][0] += dx
                  this.boxChangeFigure.points[1][0] += dx
                  this.boxChangeFigure.points[0][1] += dy
                  this.boxChangeFigure.points[1][1] += dy
                }

                // update start position
                this.boxMoveStart = [this.current.x, this.current.y]
                this.annotator.updateRedrawAll = true
                break
              }
              case 'resize': {
                switch (this.annotator.boxOverFigurePart) {
                  case 'top': {
                    this.setMinValue(this.boxChangeFigure, false, this.current.y)
                    break
                  }
                  case 'bottom': {
                    this.setMaxValue(this.boxChangeFigure, false, this.current.y)
                    break
                  }
                  case 'left': {
                    this.setMinValue(this.boxChangeFigure, true, this.current.x)	
                    break
                  }
                  case 'right': {
                    this.setMaxValue(this.boxChangeFigure, true, this.current.x)
                    break
                  }
                  case 'top-left': {
                    this.setMinValue(this.boxChangeFigure, false, this.current.y)
                    this.setMinValue(this.boxChangeFigure, true, this.current.x)
                    break
                  }
                  case 'top-right': {
                    this.setMinValue(this.boxChangeFigure, false, this.current.y)
                    this.setMaxValue(this.boxChangeFigure, true, this.current.x)
                    break
                  }
                  case 'bottom-left': {
                    this.setMaxValue(this.boxChangeFigure, false, this.current.y)
                    this.setMinValue(this.boxChangeFigure, true, this.current.x)
                    break
                  }
                  case 'bottom-right': {
                    this.setMaxValue(this.boxChangeFigure, false, this.current.y)
                    this.setMaxValue(this.boxChangeFigure, true, this.current.x)
                    break
                  }
                }

                this.annotator.updateRedrawAll = true
                break
              }
            }
          } else {
            let oldBoxOverFigure = this.annotator.boxOverFigure
            this.checkBoundingBoxPosition()
            // Redraw all only if active figure changed
            this.annotator.updateRedrawAll = oldBoxOverFigure !== this.annotator.boxOverFigure
          }									
        }

        break
      }
      case 'mouseout': 
      case 'mouseup': {
        this.checkBoundingBoxPosition()

        if (this.current.isDrawing) {
          this.current.isDrawing = false
          this.finishDrawing()
        } else {
          if (e.type === 'mouseup') {
            this.checkBoundingBoxPosition()
          } else {
            this.annotator.boxOverFigure = null
          }

          this.boxChangeFigure = null
          this.boxChangeOperation = false
        }

        break
      }
      case 'dblclick': {
        this.checkBoundingBoxPosition()
        this.annotator.boxSelected = this.annotator.boxSelected === this.annotator.boxOverFigure
          ? null
          : this.annotator.boxOverFigure

        if (this.annotator.boxSelected) {
          this.annotator.onSetCopyBox()
          //this.annotator.boxRemembered = this.annotator.boxSelected
          //this.annotator.boxRememberedFrame = this.annotator.selectedIndex
        }

        this.annotator.updateRedrawAll = true
        break
      }
    }
  }
  /**
   * Draw given box figure on given canvas
   * @param {CanvasRenderingContext2D} canvasCtx - target canvas context
   * @param {Object} figure - figure to draw
   * @param {Number} alpha - alpha level to use for brush (eraser always use 1)
   */
  drawFigure (canvasCtx, figure) {
    canvasCtx.strokeStyle = figure.color
    canvasCtx.fillStyle = figure.color
    canvasCtx.lineWidth = figure.lineWidth
    canvasCtx.globalAlpha = 1

    if (figure.points.length > 1) {
      let x1, y1, x2, y2
      [x1, y1, x2, y2] = this.normalizeBoxCoords(figure)

      // Rect
      canvasCtx.beginPath()
      canvasCtx.rect(x1, y1, x2 - x1, y2 - y1)
      canvasCtx.stroke()
      // Highlight figure on hover
      canvasCtx.globalAlpha = figure === this.annotator.boxSelected
        ? 1
        : figure === this.annotator.boxOverFigure
          ? 0.9
          : 0.7

      canvasCtx.fill()

      // 4-points
      canvasCtx.globalAlpha = 1            
      canvasCtx.beginPath()
      canvasCtx.arc(x1, y1, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()
      canvasCtx.beginPath()
      canvasCtx.arc(x1, y2, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()
      canvasCtx.beginPath()
      canvasCtx.arc(x2, y1, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()
      canvasCtx.beginPath()
      canvasCtx.arc(x2, y2, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()

      // Half-lines
      const halfWidth = Math.abs(x2 - x1) / 2
      const halfHeight = Math.abs(y2 - y1) / 2

      canvasCtx.beginPath()
      canvasCtx.arc(x1 + halfWidth, y1, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()
      canvasCtx.beginPath()
      canvasCtx.arc(x1 + halfWidth, y2, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()
      canvasCtx.beginPath()
      canvasCtx.arc(x1, y1 + halfHeight, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()
      canvasCtx.beginPath()
      canvasCtx.arc(x2, y1 + halfHeight, figure.lineWidth * 2, 0, Math.PI * 2)
      canvasCtx.fill()

    } else {
      // draw single point
      canvasCtx.beginPath()
      canvasCtx.arc(figure.points[0][0], figure.points[0][1], 4, 0, Math.PI * 2)
      canvasCtx.fill()            
    }
  }
  /**
   * Ensure that first point is top-left and second bottom-right
   * @param {Object} figure - figure object to normalize
   * @returns {Array.<Number>} - array of coordinates: x1, y1, x2, y2
   */
  normalizeBoxCoords (figure) {
    let x1 = figure.points[0][0]
    let y1 = figure.points[0][1]
    
    if (figure.points.length > 1) {
      let x2 = figure.points[1][0]
      let y2 = figure.points[1][1]

      if (x1 > x2) {
        [x1, x2] = [x2, x1]
      }

      if (y1 > y2) {
        [y1, y2] = [y2, y1]
      }

      return [x1, y1, x2, y2]
    } else {
      return [x1, y1, x1, y1]
    }
  }
  /**
   * Change figure min coordinate
   * @param {Object} figure - figure to handle
   * @param {Number} x - x or y coordinate
   * @param {Number} value - value to set
   */
  setMinValue (figure, x, value) {
    let index = x ? 0 : 1

    if (figure.points[0][index] < figure.points[1][index]) {
      figure.points[0][index] = value
    } else {
      figure.points[1][index] = value
    }
  }
  /**
   * Change figure max coordinate
   * @param {Object} figure - figure to handle
   * @param {Number} x - x or y coordinate
   * @param {Number} value - value to set
   */  
  setMaxValue (figure, x, value) {
    let index = x ? 0 : 1

    if (figure.points[0][index] > figure.points[1][index]) {
      figure.points[0][index] = value
    } else {
      figure.points[1][index] = value
    }
  }
  /**
   * Check if cursor is over one of the boxes and where exactly
   */
  checkBoundingBoxPosition () {
    if (this.current.x) {
      // checking from last to first
      let resFigure = null

      for (let i = this.annotator.selectedAnnotations.length-1; i >= 0; i--) {
        let figure = this.annotator.selectedAnnotations[i]

        // Get coordinates and swap them if needed
        let x1, y1, x2, y2
        [x1, y1, x2, y2] = this.normalizeBoxCoords(figure)

        let halfLine = this.boxLineWidth
        let halfCircle = this.boxLineWidth

        // Check
        if (this.current.x >= (x1 - halfLine) && 
            this.current.x <= (x2 + halfLine) && 
            this.current.y >= (y1 - halfLine) && 
            this.current.y <= (y2 + halfLine)) {
          // Detect exact position

          // Corners
          if (checkRange(this.current.x, x1, halfCircle) && checkRange(this.current.y, y1, halfCircle)) {
            this.annotator.boxOverFigurePart = 'top-left'
          } else if (checkRange(this.current.x, x1, halfCircle) && checkRange(this.current.y, y2, halfCircle)) {
            this.annotator.boxOverFigurePart = 'bottom-left'
          } else if (checkRange(this.current.x, x2, halfCircle) && checkRange(this.current.y, y1, halfCircle)) {
            this.annotator.boxOverFigurePart = 'top-right'
          } else if (checkRange(this.current.x, x2, halfCircle) && checkRange(this.current.y, y2, halfCircle)) {
            this.annotator.boxOverFigurePart = 'bottom-right'
          } else {
            // Edges
            if (checkRange(this.current.x, x1, halfLine) && this.current.y >= y1 && this.current.y <= y2) {
              this.annotator.boxOverFigurePart = 'left'
            } else if (checkRange(this.current.x, x2, halfLine) && this.current.y >= y1 && this.current.y <= y2) {
              this.annotator.boxOverFigurePart = 'right'
            } else if (checkRange(this.current.y, y1, halfLine) && this.current.x >= x1 && this.current.x <= x2) {
              this.annotator.boxOverFigurePart = 'top'
            } else if (checkRange(this.current.y, y2, halfLine) && this.current.x >= x1 && this.current.x <= x2) {
              this.annotator.boxOverFigurePart = 'bottom'
            } else {
              this.annotator.boxOverFigurePart = 'inner'
            }
          }

          // Stop furher checks
          resFigure = figure
          break
        }
      }

      this.annotator.boxOverFigure = resFigure

    } else {
      this.annotator.boxOverFigure = null
    }
  }
}

export default BoxTool