import * as cv from 'opencv.js'
import Copier from './Copier.js'

class LucasKanadeOpenCVCopier extends Copier {
  /**
   * Create box tool
   * @constructor
   */
  constructor (annotator, videoDebugMode, videoElement) {
    super(annotator, videoDebugMode)

    this.useVideo = true    
    this.requireEndBox = false

    this.oflowVideo = videoElement

    this.oflowVideoSize = {
      width: 0,
      height: 0
    }

    this.oflowArea = {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0
    }

    this.oflowCap = null
    this.ShiTomasi = {
      maxCorners: 60,
      qualityLevel: 0.3,
      minDistance: 7,
      blockSize: 7
    }

    this.oflowWinSize = new cv.Size(15, 15)
    this.oflowMaxLevel = 2
    this.oflowCriteria = new cv.TermCriteria(cv.TERM_CRITERIA_EPS | cv.TERM_CRITERIA_COUNT, 10, 0.03)
    this.oflowOldFrame = null
    this.oflowOldGray = new cv.Mat()
    this.oflowVars = {
      p0: new cv.Mat(),
      none: new cv.Mat(),
      zeroEle: new cv.Scalar(0, 0, 0, 255),
      mask: null,
      p1: new cv.Mat(),
      st: new cv.Mat(),
      err: new cv.Mat()
    }
    this.oflowFrame = null
    this.oflowFrameGray = new cv.Mat()

    // for debug only
    this.oflowColors = []
  }  

  start (boxPoints) {
    this.oflowVideoSize.width = this.oflowVideo.videoWidth
    this.oflowVideoSize.height = this.oflowVideo.videoHeight

    this.oflowArea.x1 = boxPoints[0][0]
    this.oflowArea.y1 = boxPoints[0][1]
    this.oflowArea.x2 = boxPoints[1][0]
    this.oflowArea.y2 = boxPoints[1][1]

    this.oflowCap = new cv.VideoCapture(this.oflowVideo)

    for (let i = 0; i < this.ShiTomasi.maxCorners; i++) {
      this.oflowColors.push(new cv.Scalar(parseInt(Math.random()*255), parseInt(Math.random()*255), parseInt(Math.random()*255), 255))
    }
  
    this.oflowOldFrame = new cv.Mat(this.oflowVideoSize.height, this.oflowVideoSize.width, cv.CV_8UC4)

    this.oflowCap.read(this.oflowOldFrame)

    cv.cvtColor(this.oflowOldFrame, this.oflowOldGray, cv.COLOR_RGB2GRAY)

    cv.goodFeaturesToTrack(
      this.oflowOldGray, 
      this.oflowVars.p0, 
      this.ShiTomasi.maxCorners,
      this.ShiTomasi.qualityLevel,
      this.ShiTomasi.minDistance,
      this.oflowVars.none,
      this.ShiTomasi.blockSize)

    this.oflowVars.mask = new cv.Mat(
      this.oflowOldFrame.rows, 
      this.oflowOldFrame.cols, 
      this.oflowOldFrame.type(), 
      this.oflowVars.zeroEle)

    this.oflowFrame = new cv.Mat(this.oflowVideoSize.height, this.oflowVideoSize.width, cv.CV_8UC4)
  }

  captureFrame () {
    this.oflowCap.read(this.oflowFrame)

    cv.cvtColor(this.oflowFrame, this.oflowFrameGray, cv.COLOR_RGBA2GRAY)

    // calculate optical flow
    cv.calcOpticalFlowPyrLK(
      this.oflowOldGray, 
      this.oflowFrameGray,
      this.oflowVars.p0,
      this.oflowVars.p1,
      this.oflowVars.st,
      this.oflowVars.err,
      this.oflowWinSize,
      this.oflowMaxLevel,
      this.oflowCriteria)

    // select good points
    let goodNew = []
    let goodOld = []

    for (let i = 0; i < this.oflowVars.st.rows; i++) {
      if (this.oflowVars.st.data[i] === 1) {
        goodNew.push(new cv.Point(this.oflowVars.p1.data32F[i*2], this.oflowVars.p1.data32F[i*2+1]))
        goodOld.push(new cv.Point(this.oflowVars.p0.data32F[i*2], this.oflowVars.p0.data32F[i*2+1]))
      }
    }

    // store point indexes in array
    let pointIndex = []

    // 1. Try to find points that within our box
    for (let i = 0; i < goodOld.length; i++) {
      if (goodOld[i].x >= this.oflowArea.x1 && goodOld[i].x <= this.oflowArea.x2 && goodOld[i].y >= this.oflowArea.y1 && goodOld[i].y <= this.oflowArea.y2) {
        pointIndex.push(i)          
      }
    }      

    if (pointIndex.length === 0) {
      if (goodOld.length > 0) {
        let minDistValue = this.getDistance(goodOld[0].x, goodOld[0].y)
        let minDistIndex = 0
  
        // 2. If p.1 does not give us anything - try to find closest point
        for (let i = 1; i < goodOld.length; i++) {
          const newDist = this.getDistance(goodOld[i].x, goodOld[i].y)
          
          if (newDist < minDistValue) {
            minDistValue = newDist
            minDistIndex = i
          }
        }
  
        pointIndex.push(minDistIndex)
      }
    }

    let res

    if (pointIndex.length > 0) {
      // Calculate average diff for points selected
      let pointMove = pointIndex.map(index => ({
        x: goodNew[index].x - goodOld[index].x,
        y: goodNew[index].y - goodOld[index].y
      }))

      res = {
        x1: pointMove.reduce((a, b) => a + b.x, 0) / pointMove.length,
        y1: pointMove.reduce((a, b) => a + b.y, 0) / pointMove.length
      }

      res.x2 = res.x1
      res.y2 = res.y1

      // DEBUG: draw the tracks
      if (this.videoDebugMode) {
        for (let i = 0; i < goodNew.length; i++) {
          cv.line(this.oflowVars.mask, goodNew[i], goodOld[i], this.oflowColors[i], 2)
          cv.circle(this.oflowFrame, goodNew[i], 5, this.oflowColors[i], -1)
        }
        cv.add(this.oflowFrame, this.oflowVars.mask, this.oflowFrame)

        // show result
        cv.imshow('canvasOutput', this.oflowFrame)  
      }

      // now update the previous frame and previous points
      this.oflowFrameGray.copyTo(this.oflowOldGray)
      this.oflowVars.p0.delete()
      this.oflowVars.p0 = new cv.Mat(goodNew.length, 1, cv.CV_32FC2)

      for (let i = 0; i < goodNew.length; i++) {
        this.oflowVars.p0.data32F[i*2] = goodNew[i].x
        this.oflowVars.p0.data32F[i*2+1] = goodNew[i].y
      }
    } else {
      // Zero movement
      res = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      }
    }

    return res  
  }

  getDistance (x, y) {
    const xDiff = x < this.oflowArea.x1
      ? this.oflowArea.x1 - x
      : x > this.oflowArea.x2
        ? x - this.oflowArea.x2
        : 0

    const yDiff = y < this.oflowArea.y1
      ? this.oflowArea.y1 - y
      : y > this.oflowArea.y2
        ? y - this.oflowArea.y2
        : 0
    
    return xDiff + yDiff
  } 
}

export default LucasKanadeOpenCVCopier