<template>
  <div class="images-selector-container px-2">
    <template v-if="imageSelectorPreview">
      <div v-for="(image, index) in images" 
          :key="index" 
          class="image-preview"
          :class="{ 'selected': selectedIndex === index }"
          @click="onClick(index)">
        <div>
          <div v-if="image.loaded" class="image-container preview-height">
            <img :src="image.image.src">
          </div>

          <div v-else class="d-flex justify-center align-center preview-height">
            <v-progress-circular indeterminate 
                          color="#4B0F42" 
                          size="30" 
                          width="4"/>
          </div>

          <div class="image-title">
            {{ image.name }}
          </div>
        </div>
      </div>
    </template>

    <v-slider
      v-else
      :value="selectedIndex"
      @change="onClick($event)"
      :min="0"
      :max="images.length - 1"
      thumb-label
      :step="1"
      ticks="always"
      hide-details
      class="mb-3 mt-10">
      <template v-slot:thumb-label="{ value }">
        {{ value + 1 }}
      </template>
    </v-slider>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ImageSelector',
  props: {
    selectedIndex: [Number, Boolean]
  },
  computed: {
    ...mapState({
      images: state => state.images.images
    }),
    imageSelectorPreview () {
      return process.env.VUE_APP_IMAGE_SELECTOR === 'preview'
    }
  },
  methods: {
		...mapActions('images', ['setSelected']),
    async onClick (index) {
      if (this.images[index].loaded) {
				await this.setSelected(index)
      }      
    }
  }
}
</script>

<style lang="stylus">
previewWidth = 150px
previewHeight = 200px
titleHeight = 30px

.images-selector-container
  display: flex
  flex-direction: row
  overflow-x: auto
  background-color: #303030

  & .image-preview
    flex-shrink: 0
    width: previewWidth
    height: previewHeight
    border-radius: 5px
    background-color: grey
    margin: 5px
    padding: 5px
    cursor: pointer
    position: relative

    & .preview-height
      height "calc(%s - %s)" % (previewHeight titleHeight)

    & .image-container
      width: 100%      
      overflow: hidden

      & img 
        width: 100%

    &.selected
      cursor: default
      background-color: #CCCCCC

		& .image-title
			position: absolute
			padding: 5px
			bottom: 0
			left: 0
			right: 0
			text-align: center
			overflow: hidden
			height: titleHeight
			vertical-align: middle

</style>