/**
 * @module tools/Fill
 */
import '@/util/Fill.js'
import Tool from './Tool.js'
/**
 * Fill tool
 * @extends Tool
 */
class FillTool extends Tool {  /**
  * Create fill tool
  * @constructor
  * @param {Object} annotator - Annotator vue component reference
  */
  constructor (annotator) {
    super(annotator, 'fill', 'f')
  }  
  /**
   * Handle all mouse events for fill 
   * @param {Event} e - mouse event 
   */
  handleMouse (e) {
    switch (e.type) {
      case 'click': {								
        this.startFigure(this.annotator.brushColor)
        this.current.figure.finished = true
        this.finishDrawing()
        break
      }
    }
  }
  /**
   * Draw given fill figure on given canvas
   * @param {CanvasRenderingContext2D} canvasCtx - target canvas context
   * @param {Object} figure - figure to draw
   * @param {Number} alpha - alpha level to use for brush (eraser always use 1)
   */
  drawFigure (canvasCtx, figure, alpha) {
    canvasCtx.fillStyle = figure.color
    canvasCtx.fillFlood(figure.points[0][0], 
                        figure.points[0][1], 10, 0, 0, 
                        this.annotator.canvasSize.width, 
                        this.annotator.canvasSize.height, alpha)
  }
}

export default FillTool