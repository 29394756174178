<template>
	<div class="form-mode-container px-3 pb-3 pt-1">
		<FormModeItem v-for="(item, index) in data" 
									:key="index"
									:data="item"/>
	</div>
</template>

<script>
import FormModeItem from '@/components/FormModeItem'

export default {
	name: 'FormMode',
	components: {
		FormModeItem
	},
	props: {
		data: Array
	}
}
</script>

<style lang="scss">
.form-mode-container {
	color: white;
}
</style>
