<template>
  <MenuItem :icon="icon" :title="title">
    <input type="file" multiple class="hidden-input-file" title="" @change="$emit('change', $event)"/>
  </MenuItem>
</template>

<script>
import MenuItem from '@/components/Sidebar/MenuItem'

export default {
  name: 'MenuItemFileInput',
  props: {
    icon: String,
    title: String
  },
  components: {
    MenuItem
  }
}
</script>

<style lang="stylus">
input[type="file"].hidden-input-file
  opacity: 0.0
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  cursor: pointer
  // We need to this to change cursor also on invisible file button
  &::-webkit-file-upload-button
    cursor:pointer
</style>