/**
 * @module tools/MagicWand
 */
import 'magic-wand-js/js/magic-wand.js'
import Tool from './Tool.js'
/**
 * MagicWand tool
 * @extends Tool
 */
class MagicWandTool extends Tool {
  /**
   * Create MagicWand tool
   * @constructor
   * @param {Object} annotator - Annotator vue component reference
   * @param {Canvas} mwCanvas - Canvas to draw magic wand moving border
   * @param {CanvasRenderingContext2D} mwCanvasCtx - Canvas context from previous one
   * @param {Canvas} mwTmpCanvas - Temporary canvas to draw magic wand filled area
   * @param {CanvasRenderingContext2D} mwTmpCanvasCtx - Canvas context from previous one
   */
  constructor (annotator, mwCanvas, mwCanvasCtx, mwTmpCanvas, mwTmpCanvasCtx) {
    super(annotator, 'magicwand', 'm')

    this.mwCanvas = mwCanvas
    this.mwCanvasCtx = mwCanvasCtx
    this.mwTmpCanvas = mwTmpCanvas
    this.mwTmpCanvasCtx = mwTmpCanvasCtx

    this.mwColorThreshold = 15
    this.mwThreshold = 15
    // Magic wand mask, also serves as a flag that we already have selected region
    this.mwMask = null
    this.mwBlurRadius = 5
    this.mwCacheInd = null
    this.mwHatchLength = 4
    this.mwHatchOffset = 0
    this.mwRedrawTimer = false
    this.mwDownPoint = {
      x: -1,
      y: -1
    }

    // Timer for magicwand contour drawing
    // Use bind to ensure mwTimerHandler will be called with correct this
    this.mwTimer = window.setInterval(this.mwTimerHandler.bind(this), 300)
  }  
  /**
   * Reset drawing state
   */
  reset () {
    super.reset()
    this.mwMask = null
    this.mwCanvasCtx.clearRect(0, 0, this.annotator.canvasSize.width, this.annotator.canvasSize.height)
  }
  /**
   * Diposes allocated resource: timer
   */
  dispose () {
    window.clearInterval(this.mwTimer)
  }
  /**
   * Handle all mouse events for magic wand
   * @param {Event} e - mouse event 
   */
  handleMouse (e) {
    switch (e.type) {
      case 'mousedown': {
        console.log(this.mwThreshold)
        // Only left button
        if (e.button === 0) {
          this.current.isDrawing = true
          this.mwDownPoint.x = this.current.x
          this.mwDownPoint.y = this.current.y									
          this.mwDrawMask(this.current.x, this.current.y)
        }

        break
      }
      case 'mouseup': {
        if (e.button === 0) {
          this.current.isDrawing = false
          // Remember theshold value
          //this.mwThreshold = this.mwColorThreshold

          if (this.mwMask) {
            let simplifyTolerant = 0
            let simplifyCount = 30
  
            this.annotator.selectedAnnotations.push({
              tool: 'magicwand',
              color: this.annotator.brushColor,
              lineWidth: 2,
              // copy initial point values, do no assign object!
              point: { 
                x: this.mwDownPoint.x, 
                y: this.mwDownPoint.y 
              },
              // eslint-disable-next-line
              contours: MagicWand.simplifyContours(MagicWand.traceContours(this.mwMask), simplifyTolerant, simplifyCount),
              mask: this.mwMask
            })
  
            // Reset all magic wand figure finished
            this.reset()
            this.annotator.updateRedrawAll = true
          }  
        }

        break
      }	
      case 'mousemove': {
        if (this.current.isDrawing) {
          if (this.current.x !== this.mwDownPoint.x || this.current.y !== this.mwDownPoint.y) {
            let dx = this.current.x - this.mwDownPoint.x,
                dy = this.current.y - this.mwDownPoint.y,
                len = Math.sqrt(dx * dx + dy * dy),
                adx = Math.abs(dx),
                ady = Math.abs(dy),
                sign = adx > ady ? dx / adx : dy / ady

            sign = sign < 0 ? sign / 5 : sign / 3
            //
            let thres = Math.min(Math.max(this.mwColorThreshold + Math.floor(sign * len), 1), 255)
            
            if (thres !== this.mwThreshold) {
              this.mwThreshold = thres
              this.mwDrawMask(this.mwDownPoint.x, this.mwDownPoint.y)
            }
          }
        }

        break
      }
      // Right click - fix magic wand contour and create figure				
      case 'contextmenu': {
        // disable default context menu
        e.preventDefault()
        break
      }								
    }
  }
  /**
   * Draw given magic wand figure on magic wand temp canvas and then copy it onto given canvas
   * @param {CanvasRenderingContext2D} canvasCtx - target canvas context
   * @param {Object} figure - figure to draw
   * @param {Number} alpha - alpha level to use for brush (eraser always use 1)
   */
  drawFigure (canvasCtx, figure) {
    var x, y, k,
      h = figure.mask.height,
      w = figure.mask.width,
      src = figure.mask.data;

    const hexToRgb = hex =>
        hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
              ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))

    this.mwTmpCanvasCtx.globalCompositeOperation = 'source-over'
    this.mwTmpCanvasCtx.clearRect(0, 0, w, h)
    var imgData = this.mwTmpCanvasCtx.createImageData(w, h)

    var color = hexToRgb(figure.color)

    for (x = 0; x < h; x++) {
      for (y = 0; y < w; y++) {
        k = y * h + x;
        if (src[k] === 1) {
          imgData.data[k*4 + 0] = color[0]
          imgData.data[k*4 + 1] = color[1]
          imgData.data[k*4 + 2] = color[2]
          imgData.data[k*4 + 3] = 255
        }
      }
    }

    this.mwTmpCanvasCtx.putImageData(imgData, 0, 0)
    canvasCtx.drawImage(this.mwTmpCanvas, 0, 0)
  }
  /**
   * Magic wand timer to draw moving contour/border
   */
  mwTimerHandler () {
    if (this.mwMask && this.annotator.activeTool === 'magicwand') {
      this.mwHatchOffset = (this.mwHatchOffset + 1) % (this.mwHatchLength * 2)
      this.mwDrawBorder() 
    }
  }

  mwDrawMask (x, y) {    
    let image = {
      data: this.annotator.selectedImage.imageData.data,
      width: this.annotator.canvasSize.width,
      height: this.annotator.canvasSize.height,
      bytes: 4
    }

    // eslint-disable-next-line
    this.mwMask = MagicWand.floodFill(image, x, y, this.mwThreshold)
    // eslint-disable-next-line
    this.mwMask = MagicWand.gaussBlurOnlyBorder(this.mwMask, this.mwBlurRadius)
    this.mwDrawBorder()
  }
  /**
   * Draw magic wand border on magic wand canvas
   */
  mwDrawBorder () {
    let x, y, i, j, k
    let w = this.annotator.canvasSize.width
    let h = this.annotator.canvasSize.height
    let imgData = this.mwCanvasCtx.createImageData(w, h)
    let res = imgData.data

    // eslint-disable-next-line
    this.mwCacheInd = MagicWand.getBorderIndices(this.mwMask)
    
    this.mwCanvasCtx.clearRect(0, 0, w, h)
    
    let len = this.mwCacheInd.length

    for (j = 0; j < len; j++) {
      i = this.mwCacheInd[j]
      x = i % w // calc x by index
      y = (i - x) / w // calc y by index
      k = (y * w + x) * 4

      if ((x + y + this.mwHatchOffset) % (this.mwHatchLength * 2) < this.mwHatchLength) { // detect hatch color 
        res[k + 3] = 255 // black, change only alpha
      } else {
        res[k] = 255 // white
        res[k + 1] = 255
        res[k + 2] = 255
        res[k + 3] = 255
      }
    }

    this.mwCanvasCtx.putImageData(imgData, 0, 0);
  }
}  

export default MagicWandTool