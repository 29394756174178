<template>
  <v-layout class="login-main" ma-0 pa-3 row justify-center align-center>
    <v-flex>
      <v-layout row wrap justify-center class="login-inner">
        <v-flex xs12 sm6 md4 pa-3>
          <img alt="Logo" src="../assets/logo.jpg" class="logo"/>
          <hr class="my-3 "/>
          <div class="white--text">
            <p>Spend more time innovating and less time cleaning heterogenous data dumps. Our software enables you to aggregate data from multiple sites, label efficiently by skilled physicians, run deep learning code and deploy models in compliance with HIPAA</p>
          </div>

        </v-flex>

        <v-flex xs12 sm6 md4 pa-3>
          <h1 class="white--text">Login</h1>
          <v-text-field label="Username" 
                        v-model="username"
                        dark hide-details
                        name="username" 
                        v-validate="'required'"
                        data-vv-as="username"></v-text-field>           
          <div class="validation-error mb-2">
            <span v-if="submitted">{{ veeErrors.first('username') }}</span>
          </div>
        
          <v-text-field label="Password" 
                        v-model="password" 
                        type="password"
                        dark hide-details
                        name="password"                         
                        v-validate="'required'"
                        data-vv-as="password"></v-text-field>             
          <div class="validation-error mb-2">
            <span v-if="submitted">{{ veeErrors.first('password') }}</span>  
          </div>

					<!--
          <v-checkbox v-model="rememberMe" color="primary"
                      label="Remember me"/>
					-->

          <v-btn type="submit" 
                 color="primary darken-1"
                 dark
                 class="white--text mx-0" 
                 :disabled="submitted && veeErrors.any()"
                 @click="onLogin()">Login</v-btn>
        </v-flex>
      </v-layout>        
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import { apiError } from '@/util/ErrorMessage.js'

export default {
  name: "LoginForm",
  data () {
    return {
      loading: false,
      username: process.env.VUE_APP_DEFAULT_USERNAME,
      password: process.env.VUE_APP_DEFAULT_PASSWORD,
      rememberMe: true,
      submitted: false
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async onLogin () {
      this.loading = true

      try {
        this.submitted = true

        await this.$validator.validateAll()

        if (!this.veeErrors.any()) {
          await this.login({
            username: this.username, 
            password: this.password,
            //rememberMe: this.rememberMe
            rememberMe: true
          })

          this.$router.push('/')
        }
      } catch (error) {
        apiError(error, 'logging in')
      }

      this.password = ''
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/vars.styl";

.login-main
  background-color: #4b0f42

  & .login-inner
    background-color: #4b0f42

  & img.logo
    height: 70px

  & hr
    border: 1px solid rgba(0,0,0,.1)

  & h1
    font-weight: 500
    font-size: 2.5rem

  & button
    width: 100%
</style>
