/**
 * Mixin containing all export routines
 * @mixin ExportMixin 
 */
import { mapState, mapMutations } from 'vuex'
import axios from 'axios'
import Zip from 'jszip'
import { apiError, apiSuccess } from '@/util/ErrorMessage.js'
import { replaceFileExtension } from '@/util/FileUtil.js'

function PointsToObject(points) {
	let res = {
		x1: points[0][0],
		y1: points[0][1],
		x2: points[1][0],
		y2: points[1][1]
	}

	if (res.x1 > res.x2) {
		[res.x1, res.x2] = [res.x2, res.x1]
	}

	if (res.y1 > res.y2) {
		[res.y1, res.y2] = [res.y2, res.y1]
	}

	return res
}

export default {
  computed: {
    ...mapState({
      images: state => state.images.images,
      multilabel: state => state.images.multilabel,
      datapointURL: state => state.images.datapointURL,
      originalName: state => state.images.originalName,
      annotationToEdit: state => state.images.annotationToEdit
    })
  },  
  methods: {
    ...mapMutations({
      setBusy: 'setBusy'
    }),
    /**
     * Save results to file on disk
     * @memberof ExportMixin
     */
    saveAnnotationToDisk () {
      this.setBusy(true)

      if (this.images.length > 1 && this.multilabel) {
        // multiple slices in zip
        this.zipExport()
          .then(res => {
            this.doFileSave(res, '.zip')
          })
          .catch(error => {
            apiError(error, 'creating zip file')
            this.setBusy(false)
          })
      } else {
        // single file in segemntation mode or !multilabel mode
        this.prepareCanvas(0).toBlob(blob => {
          this.doFileSave(blob, '.png')
        })
      }
    },
    /**
     * Function that perfrom actual file save
     * Can be called from saveFileToDisk and zipExport
     * @param {*} file - file object
     * @param {*} ext - extension to use
     * @memberof ExportMixin
     */
    doFileSave (file, ext) {
      let link = document.createElement('a')
			link.setAttribute('href', URL.createObjectURL(file))
      link.setAttribute('download', replaceFileExtension(this.originalName, ext))		
			// This method works in all browsers including FireFox
			link.dispatchEvent(new MouseEvent('click'))
      this.setBusy(false)
    },
    /**
     * Upload results to a server using 'mask' mode
     * @memberof ExportMixin
     */
    async saveAnnotationToServer (saveOptions =  { saveOnly: false, saveSilent: false }) {
      if (!saveOptions.saveSilent) {
        this.setBusy(true)
      }

			switch (this.mode) {
				case 'segmentation': {
					if (this.images.length > 1 && this.multilabel) {
						// export multiple slices in zip
						this.zipExport('mask')
							.then(res => {
								this.doFileUpload(res, 'annotation.zip', saveOptions, this.mode, null)
							})
							.catch(error => {
								apiError(error, 'creating zip file')

                if (!saveOptions.saveSilent) {                
                  this.setBusy(false)
                }
							})
					} else {
            // Export single image in png
            this.prepareCanvas(0).toBlob(blob => {
		this.doFileUpload(blob, 'annotation.png', saveOptions, this.mode, null)
            })
					}		
					break
        }
				// FORM mode
				case 'form': {
					// In form mode we just upload the answers as a form object
					let res = {}

					this.form.form.forEach(item => {
						res[item.name] = item.result
					})

					let formData = new FormData()			
					formData.append('datapoint', this.datapointURL)
					formData.append('labels', res)
		
					try {
						await axios.post('/annotations/', formData)
						apiSuccess('File successfully uploaded to the server')
						// load next image from server
						await this.onLoadFromServer('current')
					} catch (error) {
						apiError(error, 'uploading to server')
					}	
					
          if (!saveOptions.saveSilent) {
            this.setBusy(false)
          }

					break
				}
				case 'box': {
          let data = {
            version: "1.0",
            description: "Saliency Regions Export Format",
            images: []
          };

          if (this.mediaType === 'video') {
            // In video mode each image is actually a video frame
            this.images.forEach(frame => {              
              data.images.push(frame.annotations.map(a => ({
                color: a.color,
                x1: a.points[0][0],
                y1: a.points[0][1],
                x2: a.points[1][0],
                y2: a.points[1][1]
              })))
            })
          } else { 
            let colors = this.getColors().map(obj => {
              obj.regions = []
              return obj
            })
            
            this.images.forEach((image, index) => {
              data.images.push({
                idx: index,
                name: image.name,
                labels: image.annotations.reduce((acc, value) => {
                  // find the right color and add a region there
                  let cg = acc.find(cg => cg.color === value.color)
                  cg.regions.push(PointsToObject(value.points))
                  return acc
                }, colors)
              })
            
            })  
          }

          this.doFileUpload(new Blob([JSON.stringify(data)], {
            type: 'application/json'
          }), 'annotation.json', saveOptions, this.mode, null) 
						
					break
				}
        case 'line': {
          let data = {'lines': []}

          this.images.forEach(frame => {
            data.lines.push(frame.annotations.map(a => ({
              p0: [a.points[0][0], a.points[0][1]],
              p1: [a.points[1][0], a.points[1][1]]
            })))
          })

            let lines = data.lines[0]
            let line = null
            if (lines.length >= 1){
                line = lines[0];
            }
            console.log(lines)

          this.doFileUpload(new Blob([JSON.stringify(line)], {
            type: 'application/json'
          }), 'annotation.json', saveOptions, this.mode, line)

          if (!saveOptions.saveSilent) {
            this.setBusy(false)
          }

          break
        }
			}
    },
    /**
     * Function that perfrom actual file upload, used in segmentation mode
     * Can be called from saveFileToServer and zipExport
     * @param {*} data - file to upload
     * @param {*} filename - filename to send to server
     * @memberof ExportMixin
     */
      async doFileUpload (data, filename, saveOptions, mode, label) {
      let formData = new FormData()

      formData.append('datapoint', this.datapointURL)
      formData.append('filename', filename)			
      formData.append('file', data, filename)
      if (saveOptions.saveOnly){
        formData.append('type', "save")
      }
      else {
        formData.append('type', "gt")
      }
      if (mode == "line" && !saveOptions.saveOnly){
        formData.append('status', "submitted")
        formData.append('model', "brain_extractor.deface")
        formData.append('parameters', JSON.stringify(label))
      }
      else {
        formData.append('status', "done")
        if (this.annotationToEdit != null)
          formData.append('model', this.annotationToEdit.model)
      }  
        
      formData.append('meta', JSON.stringify(this.getColors()) )

      try {
        await axios.post('/annotations/', formData)  

        if (!saveOptions.saveSilent) {
          apiSuccess('File successfully uploaded to the server')
        }

        if (!saveOptions.saveOnly) {
          // load next image from server
          await this.onLoadFromServer('current')
        }
      } catch (error) {
        apiError(error, 'uploading to server')					
      }

      if (!saveOptions.saveSilent) {
        this.setBusy(false)      
      }
    },
    async skipAnnotation () {
      this.setBusy(true)

      try {
        let formData = new FormData()

        formData.append('datapoint', this.datapointURL)
        formData.append('meta', JSON.stringify({
          skip: true
        }))
  
        await axios.post('/annotations/', formData)  

        apiSuccess('Skipped')
        // load next image from server
        await this.onLoadFromServer('next')        
      } catch (error) {
        apiError(error, 'uploading to server')					
      }

      this.setBusy(false)      
    },
    /**
     * zipExport function, used by both saveFileToDisk, saveToServer 
     * @memberof ExportMixin
     */
    zipExport () {
      let files = []

      this.images.forEach((mask, index) => {       
        files.push(new Promise((resolve) => {
          this.prepareCanvas(index).toBlob(blob => {
            resolve(blob)
          })
        }))
      })

      let zip = new Zip()

      files.forEach((file, index) => {
        // Name processing
        let name = this.images[index].name.split('.')

        if (name.length > 1) {
          name.pop()         
        }

        name = name.join('.') + '.png'
        
        // add file
        // zip.file accept promises
        zip.file(name, file)              
      })
      
      return zip.generateAsync({ type:"blob" })
    },
    /**
     * Function that prepares a canvas with drawing for export
     * Used by saveAnnotationToDisk, saveAnnotationToServer, zipExport
     * @param {*} index - index of image
     * @memberof ExportMixin
     */
    prepareCanvas (index) {
      // export
      let canvas = document.createElement('canvas')
      canvas.width = this.canvasSize.width
      canvas.height = this.canvasSize.height
      this.drawAllAnnotations(canvas.getContext('2d'), index, 1, false)  
      return canvas
    },
  }
}
