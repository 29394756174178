/**
 * Error message processing
 * @module util/ErrorMessage
 */
import Vue from 'vue'

/**
 * Format object field depending on field type
 * @param {String} key - object field name
 * @param {String} value - object field value
 * @returns {String} - formatted field value string
 */
function getField (key, value) {
  return key === 'non_field_errors'
    ? value
    : `${key}: ${value}<br/>`
}

/**
 * Processes error object and returns error message string
 * @param {Object} error - error object
 * @param {String} operation - optional operation name string
 * @returns {String} - extracted & formatted error message
 */
function processErrorMessage (err, operation) {
  // switch to response object
  let error = err.response ? err.response : err

  // message caption
  let res = operation
    ? `Error(s) occured while ${operation}:`
    : 'Error(s) occured:'

  // if error is object
  if (typeof(error) === "object") {
    if (error.data && typeof(error.data) === "object") {
      res += '<br/>'

      Object.keys(error.data).forEach(key => {
        let x = error.data[key]

        if (Array.isArray(x)) {
          x.forEach(y => {
            res += getField(key, y)
          })
        } else {
          res += getField(key, x)
        }
      })
    } else {
      res += `<br/>${error.toString()}`
    }

    // status text and code
    if (error.statusText) {
      res += `<br/>HTTP status: ${error.statusText}`

      if (error.status) {
        res += ` (${error.status})`
      }
    } else {
      // check for HTTP status without statusText
      if (error.status) {
        res += `<br/>HTTP status code: ${error.status}`
      }
    }
  } else {
    if (Array.isArray(error)) {
      res += error.join('<br/>')
    } else {
      // should be a string
      res += `<br/>${error}`
    }
  }

  return res
}

/**
 * Show error message with close button
 * Uses processErrorMessage for error object handling
 * @param {Object} error - error object
 * @param {String} operation - optional operation name string
 */
function apiError (error, operation) {
  Vue.toasted.error(processErrorMessage(error, operation))
}
/**
 * Shorthand for successful toast message
 * @param {String} text - message text
 */
function apiSuccess (text) {
  Vue.toasted.success(text)
}

export {
  processErrorMessage,
	apiError,
	apiSuccess
}