<template>
  <v-list-item>
    <div class="s-container">
      <div
        v-if="title"
        class="label">
        {{ title }}
      </div>

      <div class="slider-container">
        <span
          v-if="iconMinus"
          class="mdi"
          :class="iconMinus"/>

        <v-range-slider
          :value="value"
          @input="$emit('input', $event)"          
          class="align-center"
          :min="min"
          :max="max"
          :step="step"
          hide-details
          thumb-label
          @change="$emit('change', $event)"/>

        <span
          v-if="iconPlus"
          class="mdi"
          :class="iconPlus"/>
      </div>
    </div>
  </v-list-item>      
</template>

<script>
export default {
  name: 'MenuRangeSlider',
  props: {
    value: Array,
    title: String,
    min: Number,
    max: Number,
    step: Number,
    iconMinus: String,
    iconPlus: String
  }
}
</script>

<style lang="scss">
.label {
  display: inline-block;
  width: 10em;
  font-size: 0.8em;
}
div.s-container {
  width: 100%;

  .slider-container {
    display: flex;
    flex-direction: flex-row;
    align-items: center;

    span.mdi {
      font-size: 24px;
      cursor: pointer;
    }
  }

}
</style>
