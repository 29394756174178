import axios from 'axios'
import store from '@/store/store.js'

/**
 * Removes baseURL part from given URL
 * @param {String} url - Source URL
 * @param {String} mutation - mutation name
 */
function setURLParam (url, mutation) {
  if (url) {
    let baseURL = axios.defaults.baseURL

    if (url.indexOf(baseURL) === 0) {
      url = url.substring(baseURL.length)
    }

    store.commit(mutation, url)
  } else {
    store.commit(mutation, false)
  }
}

export {
  setURLParam
}

