/**
 * File name handling utils
 * @module util/FileUtil
 */

/** 
 * Replace file extension with new one
 * @param {String} filename - filename
 * @param {String} newext - new extension
 * @returns {String} - filename with replaced extension
 */
function replaceFileExtension(filename, newext) {
  let a = filename.split('.')
  let e = newext

  if (e.length > 0 && e[0] !== '.') {
    e = '.' + e
  }

  if (a.length > 1) {
    a.pop()
    return a.join('.') + e
  } else {
    return filename + e
  }
}
/**
 * Extracts file extension from given path/filename
 * @param {String} filename - incoming filename/path
 * @returns {String} - file extension (last part in path)
 */
function getFileExtension (filename) {
  let a = filename.split('.')
  return a.length > 1 ? a.pop() : ''
}
/**
 * Checks if given path/filename is generic graphics file format
 * @param {String} filename
 * @returns {Boolean} - true if graphics file
 */
function isGraphicFile (filename) {
  let ext = getFileExtension(filename).toLowerCase()
  return ['png', 'jpeg', 'jpg', 'gif', 'jfif'].includes(ext)
}

function isVideoFile (filename) {
  let ext = getFileExtension(filename).toLowerCase()
  return ['mp4', 'mov', 'avi'].includes(ext)
}

export {
  replaceFileExtension,
  getFileExtension,
  isGraphicFile,
  isVideoFile
}
