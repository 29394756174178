function isNormalInteger(str) {
  var n = Math.floor(Number(str))
  return n !== Infinity && String(n) === str && n >= 0
}

const fillArray = (start, stop, step) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)

const reverseFillArray = (start, stop, step) =>
  Array(Math.ceil((stop + start) / step))
    .fill(start)
    .map((x, y) => x - y * step)

export {
  isNormalInteger,
  fillArray,
  reverseFillArray,
}