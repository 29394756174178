/**
 * Converts incoming Blob object into Image object and image data
 * @module util/BLOBToImage
 * 
 * @param {Blob} blob - incoming blob object
 * @returns {{ image: Image, imageData: ImageData }} - resulting object with Image & ImageData objects
 */
export default function (blob) {
  return new Promise((resolve, reject) => {
    try {
      let image = new Image()

      image.onload = () => {
				// Extract ImageData for magic wand
				let tempCtx = document.createElement("canvas").getContext("2d")
				tempCtx.canvas.width = image.width
				tempCtx.canvas.height = image.height
				tempCtx.drawImage(image, 0, 0);
				let imageData = tempCtx.getImageData(0, 0, image.width, image.height);				

        resolve({
					image,
					imageData
				})      
      }
  
      image.src = URL.createObjectURL(blob) 
    } catch (error) {
      reject(error)
    }
  })
}