import Vue from 'vue'
import Vuex from 'vuex'

import images from '@/store/images'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    images
  },
  state: {
    // Global busy state
    busy: false
  },
  mutations: {
    setBusy (state, busy) {
      state.busy = busy
    }
  }
})
